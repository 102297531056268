import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: () => ({
    loggedIn: false,
    redirect: '',
    loadingAuth: true,
    loading: {
      status: false,
      message: ''
    },
    user: null,
    tempUser: null
  }),
  getters,
  actions,
  mutations
};
