// String transform
export const firstLetterUppercase = val =>
  !val ? '' : `${val.toString().charAt(0).toUpperCase()}${val.toString().slice(1)}`;

export const trimAndUppercase = val => (val ? val.replace(/\s/g, '').trim().toUpperCase() : '');

export const uppercase = val => (val ? val.toUpperCase() : '');

export const roundPrice = val => (val ? val.toFixed(2).toString().replace('.', ',') : '');

export const capitalize = val => {
  if (!val) return '';
  return val
    .toString()
    .replace(/[A-zÀ-ú0-9]\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const utf8ToBase64 = str => {
  try {
    return window.btoa(unescape(encodeURIComponent(str)));
  } catch (error) {
    return str;
  }
};

export const base64ToUtf8 = str => {
  try {
    return decodeURIComponent(escape(window.atob(str)));
  } catch (error) {
    return str;
  }
};

export const isBase64Encode = str =>
  /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(str);

// Base64 to Blob
export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const pascalToKebab = str =>
  str.replace(/\.?([A-Z]+)/g, (_, $2) => `-${$2.toLowerCase()}`).replace(/^-/, '');
