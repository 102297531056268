import { injectScript } from '@/utils/helpers/script';

export default defineNuxtPlugin(() => {
  onNuxtReady(async () => {
    // Cookiebot config: https://www.cookiebot.com/es/developer
    await injectScript('https://consent.cookiebot.com/uc.js', 'Cookiebot', false, {
      id: 'Cookiebot',
      'data-cbid': process.env.APP_COOKIEBOT_ID,
      'data-culture': process.env.APP_LOCALE,
      'data-blockingmode': 'auto' // If value is 'auto' then automatically blocks all cookies until the user has consented
    });
  });
});
