/* eslint-disable max-lines */
import strapiService from '@/services/api/strapi/strapiService';
import {
  lastReports,
  articleBySlug,
  articlesByAuthor,
  articlesSubsector,
  articlesSavedById,
  articlesSubsectors,
  articlesMostRecent,
  articlesCategorySlug,
  articleReportVersions,
  articlesByInfCategory,
  articlesByCoordinator
} from '@/services/graphql/articles.gql';
import {
  eventsByTags,
  eventByIdQuery,
  eventsCalendar,
  eventsBySeries,
  eventsBySubsector,
  eventByHighLighted,
  eventsBySubsectors
} from '~/services/graphql/events.gql';
import {
  projectsByCenters,
  projectsBySlug,
  projectsSavedById
} from '~/services/graphql/projects.gql';
import { getCommonsPage } from '@/services/graphql/commons.gql';
import { homeQuery } from '@/services/graphql/home.gql';
import { innovationQuery } from '@/services/graphql/innovation.gql';
import { legalQuery } from '@/services/graphql/legal.gql';
import { processQuery } from '@/services/graphql/process.gql';
import {
  meteoQuery,
  cxtierraQuery,
  landingCxtierraQuery,
  irrigationQuery,
  fertilizationQuery,
  biologicControlQuery
} from '~/services/graphql/products.gql';
import { landingPageTraining } from '~/services/graphql/training.gql';
import {
  newsSingleTypeQuery,
  newsBySlugQuery,
  newsBySubsector,
  newsArticles
} from '~/services/graphql/news.gql';
import { authorDetailQuery } from '~/services/graphql/authors.gql';
import {
  infographicBySubsectorQuery,
  latestInfographic
} from '~/services/graphql/infographics.gql';
import {
  marketQuery,
  lastMarketComments,
  marketCommentsBySlug,
  subsectorSectionQuery,
  marketCommentsBySubsector
} from '~/services/graphql/market.gql';
import { subsectorsQuery, subsectorsMarketQuery } from '~/services/graphql/subsectors.gql';
import { videosSavedById } from '~/services/graphql/videos.gql';
import { centersQuery } from '~/services/graphql/centers.gql';
import { aboutQuery } from '~/services/graphql/about.gql';
import {
  lastPost,
  postBySlug,
  blogBySlug,
  blogsByAuthor,
  communityQuery,
  postsSavedById,
  lastPostByBlog,
  blogsWithAuthors
} from '~/services/graphql/community.gql';
import { surveyBySlug, feedbackByPath } from '~/services/graphql/surveys.gql';
import { invitationBySlug } from '~/services/graphql/invitations.gql';
import {
  startupsQuery,
  callForProposal,
  callForProposalBySlug,
  dynamicFormByStartupSlug,
  dynamicFormBySlug
} from '~/services/graphql/startups.gql';
import { mapGraphQuery } from '~/utils/helpers/object';

export const getHome = async locale => {
  const {
    data: { home }
  } = await strapiService.gqlQuery(homeQuery, { locale });
  return { data: { home: mapGraphQuery(home) } };
};

export const getInnovation = async locale => {
  const {
    data: { innovation }
  } = await strapiService.gqlQuery(innovationQuery, { locale });
  return { data: { innovation: mapGraphQuery(innovation) } };
};
export const getEventById = async (locale, slug) => {
  const {
    data: { events }
  } = await strapiService.gqlQuery(eventByIdQuery, { locale, slug });
  return { data: { events: mapGraphQuery(events) } };
};

export const getEventsCalendar = async locale => {
  const {
    data: { events }
  } = await strapiService.gqlQuery(eventsCalendar, { locale });
  return { data: { events: mapGraphQuery(events) } };
};

export const getEventsByTags = async (locale, limit, slug, tags) => {
  const {
    data: { events }
  } = await strapiService.gqlQuery(eventsByTags, {
    locale,
    limit,
    slug,
    tags
  });
  return { data: { events: mapGraphQuery(events) } };
};

export const getEventsBySerie = async (locale, serie, slug) => {
  const {
    data: { events }
  } = await strapiService.gqlQuery(eventsBySeries, { locale, serie, slug });
  return { data: { events: mapGraphQuery(events) } };
};

export const getEventsBySubsector = async (locale, limit, subsector) => {
  const {
    data: { events }
  } = await strapiService.gqlQuery(eventsBySubsector, {
    locale,
    limit,
    subsector
  });
  return { data: { events: mapGraphQuery(events) } };
};

export const getEventsBySubsectors = async (locale, limit, subsectors) => {
  const {
    data: { events }
  } = await strapiService.gqlQuery(eventsBySubsectors, {
    locale,
    limit,
    subsectors
  });
  return { data: { events: mapGraphQuery(events) } };
};
export const getCommons = async locale => {
  const {
    data: { common }
  } = await strapiService.gqlQuery(getCommonsPage, { locale });
  return { data: { common: mapGraphQuery(common) } };
};

export const getMarket = async locale => {
  const {
    data: { market }
  } = await strapiService.gqlQuery(marketQuery, { locale });
  return { data: { market: mapGraphQuery(market) } };
};

export const getSubsectorSection = async locale => {
  const {
    data: { market }
  } = await strapiService.gqlQuery(subsectorSectionQuery, { locale });
  return { data: { market: mapGraphQuery(market) } };
};

export const getMarketCommentsBySubsector = async (locale, limit, slug) => {
  const {
    data: { marketComments }
  } = await strapiService.gqlQuery(marketCommentsBySubsector, { locale, limit, slug });
  return { data: { marketComments: mapGraphQuery(marketComments) } };
};

export const getLastMarketComment = async (locale, limit) => {
  const {
    data: { marketComments }
  } = await strapiService.gqlQuery(lastMarketComments, { locale, limit });
  return { data: { marketComments: mapGraphQuery(marketComments) } };
};

export const getCenters = async locale => {
  const {
    data: { center }
  } = await strapiService.gqlQuery(centersQuery, { locale });
  return { data: { center: mapGraphQuery(center) } };
};

export const getAbout = async locale => {
  const {
    data: { about }
  } = await strapiService.gqlQuery(aboutQuery, { locale });
  return { data: { about: mapGraphQuery(about) } };
};

export const getArticleBySlug = async (locale, slug) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articleBySlug, { locale, slug });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getmarketCommentsBySlug = async (locale, slug) => {
  const {
    data: { marketComments }
  } = await strapiService.gqlQuery(marketCommentsBySlug, { locale, slug });
  return { data: { marketComments: mapGraphQuery(marketComments) } };
};

export const getArticleReportVersions = async (locale, slug) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articleReportVersions, { locale, slug });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getArticlesMostRecent = async (locale, limit, categories) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesMostRecent, { locale, limit, categories });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getArticlesCategorySlug = async (locale, limit, category, slug) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesCategorySlug, {
    locale,
    limit,
    category,
    slug
  });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getArticlesByInfCategory = async (locale, limit, category) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesByInfCategory, { locale, limit, category });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getLastReports = async (locale, limit) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(lastReports, { locale, limit });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getArticlesSubsector = async (locale, limit, subsector) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesSubsector, { locale, limit, subsector });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getArticlesSubsectors = async (locale, limit, subsectors) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesSubsectors, {
    locale,
    limit,
    subsectors
  });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getProjectsByCenters = async (locale, limit, sort, centers) => {
  const {
    data: { projects }
  } = await strapiService.gqlQuery(projectsByCenters, { locale, limit, sort, centers });
  return { data: { projects: mapGraphQuery(projects) } };
};

export const getProjectsBySlug = async (locale, slug) => {
  const {
    data: { projects }
  } = await strapiService.gqlQuery(projectsBySlug, { locale, slug });
  return { data: { projects: mapGraphQuery(projects) } };
};
export const getEventHighLighted = async (locale, limit) => {
  const {
    data: { events }
  } = await strapiService.gqlQuery(eventByHighLighted, { locale, limit });
  return { data: { events: mapGraphQuery(events) } };
};

export const getLegalPages = async locale => {
  const {
    data: { legal }
  } = await strapiService.gqlQuery(legalQuery, { locale });
  return { data: { legal: mapGraphQuery(legal) } };
};

export const getLandingPageTraining = async locale => {
  const {
    data: { training }
  } = await strapiService.gqlQuery(landingPageTraining, { locale });
  return { data: { training: mapGraphQuery(training) } };
};

export const getProcess = async locale => {
  const {
    data: { process }
  } = await strapiService.gqlQuery(processQuery, { locale });
  return { data: { process: mapGraphQuery(process) } };
};

export const getProductsInfo = async locale => {
  const {
    data: { cxtierra }
  } = await strapiService.gqlQuery(cxtierraQuery, { locale });
  return { data: { key: mapGraphQuery(cxtierra) } };
};

export const getProductsMeteo = async locale => {
  const {
    data: { meteo }
  } = await strapiService.gqlQuery(meteoQuery, { locale });
  return { data: { key: mapGraphQuery(meteo) } };
};

export const getProductsIrrigation = async locale => {
  const {
    data: { irrigation }
  } = await strapiService.gqlQuery(irrigationQuery, { locale });
  return { data: { key: mapGraphQuery(irrigation) } };
};

export const getProductsFertilization = async locale => {
  const {
    data: { fertilization }
  } = await strapiService.gqlQuery(fertilizationQuery, { locale });
  return { data: { key: mapGraphQuery(fertilization) } };
};

export const getProductsPest = async locale => {
  const {
    data: { biologicControl }
  } = await strapiService.gqlQuery(biologicControlQuery, { locale });
  return { data: { key: mapGraphQuery(biologicControl) } };
};

export const getLandingCxtierra = async locale => {
  const {
    data: { landingCxtierra }
  } = await strapiService.gqlQuery(landingCxtierraQuery, { locale });
  return { data: { landingCxtierra: mapGraphQuery(landingCxtierra) } };
};

export const getAuthorBySlug = async (locale, slug) => {
  const {
    data: { authors }
  } = await strapiService.gqlQuery(authorDetailQuery, { locale, slug });
  return { data: { authors: mapGraphQuery(authors) } };
};

export const getInfographicBySubsector = async (locale, limit, subsector) => {
  const {
    data: { infographics }
  } = await strapiService.gqlQuery(infographicBySubsectorQuery, { locale, limit, subsector });
  return {
    data: { infographics: mapGraphQuery(infographics) }
  };
};

export const getInfographic = async locale => {
  const {
    data: { infographics }
  } = await strapiService.gqlQuery(latestInfographic, { locale });
  return {
    data: { infographics: mapGraphQuery(infographics) }
  };
};

export const getArticlesByAuthor = async (locale, slug, limit) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesByAuthor, { locale, slug, limit });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getArticlesByCoordinator = async (locale, slug) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesByCoordinator, { locale, slug });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getSectors = async locale => {
  const {
    data: { subsectors }
  } = await strapiService.gqlQuery(subsectorsQuery, { locale });
  return { data: { subsectors: mapGraphQuery(subsectors) } };
};

export const getSubsectors = async locale => {
  const {
    data: { subsectors }
  } = await strapiService.gqlQuery(subsectorsMarketQuery, { locale });
  return { data: { subsectors: mapGraphQuery(subsectors) } };
};

export const getNewsSingleType = async locale => {
  const {
    data: { newsSingleType }
  } = await strapiService.gqlQuery(newsSingleTypeQuery, { locale });
  return { data: { newsSingleType: mapGraphQuery(newsSingleType) } };
};

export const getNewsBySlug = async (locale, slug) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(newsBySlugQuery, { locale, slug });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getNewsSubsector = async (locale, limit) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(newsBySubsector, { locale, limit });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getNewsArticles = async (locale, limit) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(newsArticles, { locale, limit });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getArticlesSavedById = async (locale, entities) => {
  const {
    data: { articles }
  } = await strapiService.gqlQuery(articlesSavedById, { locale, entities });
  return { data: { articles: mapGraphQuery(articles) } };
};

export const getVideosSavedById = async (locale, entities) => {
  const {
    data: { videoLibraries }
  } = await strapiService.gqlQuery(videosSavedById, { locale, entities });
  return { data: { videoLibraries: mapGraphQuery(videoLibraries) } };
};

export const getProjectsSavedById = async (locale, entities) => {
  const {
    data: { projects }
  } = await strapiService.gqlQuery(projectsSavedById, { locale, entities });
  return { data: { projects: mapGraphQuery(projects) } };
};

export const getPostsSavedById = async entities => {
  const {
    data: { posts }
  } = await strapiService.gqlQuery(postsSavedById, { entities });
  return { data: { posts: mapGraphQuery(posts) } };
};

export const getSurveyBySlug = async (locale, slug) => {
  const {
    data: { surveys }
  } = await strapiService.gqlQuery(surveyBySlug, { locale, slug });
  return { data: { surveys: mapGraphQuery(surveys) } };
};

export const getFeedbackByPath = async (locale, path) => {
  const {
    data: { feedbacks }
  } = await strapiService.gqlQuery(feedbackByPath, { locale, path });
  return { data: { feedbacks: mapGraphQuery(feedbacks) } };
};

export const getPostBySlug = async slug => {
  const {
    data: { posts }
  } = await strapiService.gqlQuery(postBySlug, { slug });
  return { data: { posts: mapGraphQuery(posts) } };
};

export const getDynamicFormBySlug = async (locale, slug) => {
  const {
    data: { dynamicForms }
  } = await strapiService.gqlQuery(dynamicFormBySlug, { locale, slug });
  return { data: { dynamicForms: mapGraphQuery(dynamicForms) } };
};

export const getBlogBySlug = async (locale, slug) => {
  const {
    data: { blogs }
  } = await strapiService.gqlQuery(blogBySlug, { locale, slug });
  return { data: { blogs: mapGraphQuery(blogs) } };
};

export const getCallForProposalBySlug = async (locale, slug) => {
  const {
    data: { callForProposals }
  } = await strapiService.gqlQuery(callForProposalBySlug, { locale, slug });
  return { data: { callForProposals: mapGraphQuery(callForProposals) } };
};

export const getCallForProposal = async locale => {
  const {
    data: { callForProposals }
  } = await strapiService.gqlQuery(callForProposal, { locale });
  return { data: { callForProposals: mapGraphQuery(callForProposals) } };
};

export const getDynamicFormByStartupSlug = async (locale, slug) => {
  const {
    data: { callForProposals }
  } = await strapiService.gqlQuery(dynamicFormByStartupSlug, { locale, slug });
  return { data: { callForProposals: mapGraphQuery(callForProposals) } };
};

export const getCommunity = async locale => {
  const {
    data: { community }
  } = await strapiService.gqlQuery(communityQuery, { locale });
  return { data: { community: mapGraphQuery(community) } };
};

export const getStartups = async locale => {
  const {
    data: { startup }
  } = await strapiService.gqlQuery(startupsQuery, { locale });
  return { data: { startup: mapGraphQuery(startup) } };
};

export const getBlogsWithAuthors = async (locale, limit) => {
  const {
    data: { blogs }
  } = await strapiService.gqlQuery(blogsWithAuthors, { locale, limit });
  return { data: { blogs: mapGraphQuery(blogs) } };
};

export const getLastPost = async limit => {
  const {
    data: { posts }
  } = await strapiService.gqlQuery(lastPost, { limit });
  return { data: { posts: mapGraphQuery(posts) } };
};

export const getLastPostByBlog = async (limit, blogSlug) => {
  const {
    data: { posts }
  } = await strapiService.gqlQuery(lastPostByBlog, { limit, blogSlug });
  return { data: { posts: mapGraphQuery(posts) } };
};

export const getBlogsByAuthor = async (locale, slug) => {
  const {
    data: { blogs }
  } = await strapiService.gqlQuery(blogsByAuthor, { locale, author_slug: slug });
  return { data: { blogs: mapGraphQuery(blogs) } };
};

export const getInvitationBySlug = async (locale, slug) => {
  const {
    data: { invitations }
  } = await strapiService.gqlQuery(invitationBySlug, { locale, slug });
  return { data: { invitations: mapGraphQuery(invitations) } };
};
