// Privates local storage
export const localStorageGetObject = key => JSON.parse(localStorage.getItem(key));
export const localStorageSetItem = (key, value) => localStorage.setItem(key, JSON.stringify(value));
export const localStorageRemoveItem = key => localStorage.removeItem(key);

// Privates session storage
export const sessionStorageGetObject = key => JSON.parse(sessionStorage.getItem(key));
export const sessionStorageSetItem = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value));
export const sessionStorageRemoveItem = key => sessionStorage.removeItem(key);
