import moment from 'moment-timezone';
import 'moment/dist/locale/es';

const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY';
const DATE_FORMAT_NAME_MONTH = 'DD MMMM YYYY';
const DATE_FORMAT_MONTH_ABBREVIATION = 'D MMM YYYY';
const DATE_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss';
const DATE_FORMAT_TIME = 'HH:mm';
const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
const DATE_FORMAT_DESC = 'Do MMM, YYYY';
const DATE_FORMAT_NAME_MONTH_ONLY = 'MMMM';
const DATE_FORMAT_ABBREVIATION_MONTH_ONLY = 'MMM';
const DATE_FORMAT_NUMBER_DAY_ONLY = 'DD';
const DATE_FORMAT_TIME_HOUR = 'HH:mm [h]';
const DATE_FORMAT_TIME_HOURS_MINUTES = 'H [h] mm [min]';
const DATE_FORMAT_DAY_MONTH = 'DD MMMM';
const DATE_FORMAT_MONTH_YEAR = 'MMM YYYY';
const DATE_FORMAT_UNIX_TIMESTAMP = 'X';
const DATE_FORMAT_FULL_DESC = 'dddd, D [de] MMMM [de] YYYY';

moment.updateLocale(
  process.env.APP_LOCALE,
  () => import(`@/locales/${process.env.APP_LOCALE}/moment`)
);

export const momentUpdateLocale = (lang, config) => moment.updateLocale(lang, config);

export const dateToday = () => moment().tz('Europe/Madrid');

export const dateTodayUnixTimestamp = () =>
  moment().tz('Europe/Madrid').format(DATE_FORMAT_UNIX_TIMESTAMP);

// Dates format
export const dateToString = (val, format = DATE_FORMAT_DEFAULT) =>
  val ? moment(val).tz('Europe/Madrid').format(format) : '';

export const dateToStringWithNameMonth = val => dateToString(val, DATE_FORMAT_NAME_MONTH);

export const dateWithNameMonthOnly = val => dateToString(val, DATE_FORMAT_NAME_MONTH_ONLY);

export const dateWithAbbreviationMonthOnly = val =>
  dateToString(val, DATE_FORMAT_ABBREVIATION_MONTH_ONLY).replace('.', '');

export const dateMonthAbbreviation = val => dateToString(val, DATE_FORMAT_MONTH_ABBREVIATION);

export const dateWithDayOnly = val => dateToString(val, DATE_FORMAT_NUMBER_DAY_ONLY);

export const dateWithDayAndMonth = val => dateToString(val, DATE_FORMAT_DAY_MONTH);

export const dateWithMonthAndYear = val =>
  dateToString(val, DATE_FORMAT_MONTH_YEAR).replace('.', '');

export const timeWithHoursAndMinutes = val => dateToString(val, DATE_FORMAT_TIME);

export const timeWithHours = val => moment(val, 'HH:mm:ss').format(DATE_FORMAT_TIME_HOUR);

export const timeWithMinutes = val =>
  moment.utc().startOf('day').add(val, 'minutes').format(DATE_FORMAT_TIME_HOURS_MINUTES);

export const dateToStringDescription = val => dateToString(val, DATE_FORMAT_DESC);

export const stringToDate = (val, format = DATE_FORMAT_DEFAULT) =>
  moment(val, format).tz('Europe/Madrid');

export const dateToISO = (val, format = DATE_FORMAT_DEFAULT) =>
  val ? moment(val, format).tz('Europe/Madrid').format(DATE_FORMAT_ISO) : '';

export const isoToDate = (val, format = DATE_FORMAT_ISO) =>
  val ? moment(val, format).tz('Europe/Madrid').format(DATE_FORMAT_DEFAULT) : '';

export const changeFormatFromYearFirst = val =>
  moment(val, DATE_FORMAT_YYYY_MM_DD).tz('Europe/Madrid').format(DATE_FORMAT_DEFAULT);

export const isValidDate = (val, format = DATE_FORMAT_DEFAULT) => moment(val, format).isValid();

export const dateToCalendarPicker = val => dateToString(val, DATE_FORMAT_YYYY_MM_DD);

export const dateToFullDesc = val => dateToString(val, DATE_FORMAT_FULL_DESC);

export const dateNormal = val => dateToString(val, DATE_FORMAT_DEFAULT);

export const dateJoinWithTime = (date, time) =>
  moment(`${date} ${time}`).tz('Europe/Madrid').format(DATE_FORMAT_ISO);

export const dateIsEarlier = (date, value) => {
  const formatedDate = moment(date).utc().format(DATE_FORMAT_ISO);
  const formatedSecondDate = moment(value).utc().format(DATE_FORMAT_ISO);
  return moment(formatedDate).diff(formatedSecondDate) >= 0;
};

export const differenceBetweenDates = val => {
  const now = moment().format(DATE_FORMAT_ISO);
  const expiration = moment(val).format(DATE_FORMAT_ISO);
  // get the difference between the moments
  const diff = moment(expiration).diff(now);
  // express as a duration
  const diffDuration = moment.duration(diff);
  return diffDuration.months() > 0
    ? diffDuration.months() + ' m ' + diffDuration.days() + ' d'
    : diffDuration.days() === 0
      ? diffDuration.hours() + ' h ' + diffDuration.minutes() + ' min'
      : diffDuration.days() + ' d ' + diffDuration.hours() + ' h';
};
export const differenceBetweenDatesWithText = val => {
  const now = moment().format(DATE_FORMAT_ISO);
  const notification = moment(val).utc().local().format(DATE_FORMAT_ISO);
  // get the difference between the moments
  const diff = moment(now).diff(notification);
  // express as a duration
  const diffDuration = moment.duration(diff);
  return diffDuration.months() > 0
    ? getTimeText(diffDuration.months(), 'month')
    : Math.abs(diffDuration.weeks()) > 0
      ? getTimeText(diffDuration.weeks(), 'week')
      : Math.abs(diffDuration.days()) > 0
        ? getTimeText(diffDuration.days(), 'day')
        : Math.abs(diffDuration.hours()) > 0
          ? getTimeText(diffDuration.hours(), 'hour')
          : Math.abs(diffDuration.minutes()) > 0
            ? getTimeText(diffDuration.minutes(), 'minute')
            : 'menos de un minuto';
};
export const isLessThanOneDay = val => {
  const now = moment().format(DATE_FORMAT_ISO);
  const notification = moment(val).utc().format(DATE_FORMAT_ISO);
  return moment(now).diff(notification, 'days') <= 0;
};

const getTimeText = (value, type) => {
  const time = Math.abs(value);
  let text = '';
  const texts = {
    month: 'mes',
    week: 'semana',
    day: 'día',
    hour: 'hora',
    minute: 'minuto'
  };
  if (type !== 'month') {
    text = time + ' ' + texts[type] + (time > 1 ? 's' : '');
  } else {
    text = time + ' ' + texts[type] + (time > 1 ? 'es' : '');
  }

  return text;
};

export const countDown = deadline => {
  const now = moment().format(DATE_FORMAT_ISO);
  const expiration = moment(deadline).format(DATE_FORMAT_ISO);
  const diff = moment(expiration).diff(now);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60)) - days * 24;
  return now <= expiration ? `${days} d ${hours} h` : '';
};

export const addTime = (date, time = 0, type) => {
  const expiredDate = moment(date);
  return expiredDate.add(time, type);
};

export const setHour = (date, hour) => {
  const momentDate = moment(date + ' ' + hour, DATE_FORMAT_ISO);
  return momentDate;
};

export const isSameOrBefore = date => {
  const now = moment().format(DATE_FORMAT_YYYY_MM_DD);
  return moment(now).isSameOrBefore(date);
};

export const getTimestamp = () => {
  const today = new Date().getTime() / 1000;
  return today;
};
export const getTimestampYesterday = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0); // Ajustar la hora a la medianoche del día anterior
  return timestamp;
};

export const getDateYesterday = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  yesterday.setHours(23, 0, 0, 0);
  const timestamp = Math.floor(yesterday.getTime() / 1000);

  return timestamp;
};
