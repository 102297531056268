import store from '@/store';

export default defineNuxtRouteMiddleware(to => {
  const slashReplacer = paths => {
    if (Array.isArray(paths)) return paths.map(path => path.replace(/\//g, '')).filter(Boolean);
    else return paths.replace(/\//g, '');
  };
  const path = slashReplacer(to.path);
  const pathsLogoutAllowed = slashReplacer([REGISTER_BASE_URL]);
  const pathsLoginAllowed = slashReplacer([
    FAVOURITES_URL,
    MY_EVENTS_BASE_URL,
    PROFILE_PAGE,
    MY_STARTUPS_BASE_UR
  ]);
  if (store.getters['auth/getByField']('loggedIn')) {
    if (pathsLogoutAllowed.includes(path) && to.query.type !== 'event') {
      // WIP
      /* return navigateTo('/'); */
    }
  } else if (pathsLoginAllowed.includes(path)) {
    return navigateTo(`/?redirect=${encodeURIComponent(path)}`);
  }
});
