export default class profileService {
  /* GET */

  static async getProfileData(pathParam, idToken) {
    let result;
    const { $axios, $axiosManualToken } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}`;
    if (idToken) {
      const { data } = await $axiosManualToken(idToken).fetchGet(url);
      result = data;
    } else {
      const { data } = await $axios.fetchGet(url);
      result = data;
    }
    return result;
  }

  static async getCalls() {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/startupForm?status_finish=true`;
    const { data } = await $axios.fetchGet(url);
    return data;
  }

  static async getCountrys(pathParam, language) {
    const { $axios } = useNuxtApp();
    const url = `/cultivos/${pathParam}` + language;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async getProvinces() {
    const { $axios } = useNuxtApp();
    const url = `/cultivos/provinces`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async getMunicipalities(province) {
    const { $axios } = useNuxtApp();
    const url = `/cultivos/municipality?provincia=${province}&readFromDB=true`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async checkUserIsEnrolledInEvent(slug) {
    const { $axios } = useNuxtApp();
    const url = `courses/userEnrolled/${slug}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  // Obtiene los cursos de un usuario logado
  static async modalCourses(option = false, limit = 5) {
    const { $axios } = useNuxtApp();
    const url = `courses/my?next=${option}&limit=${limit}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async userCourses(option = false) {
    const { $axios } = useNuxtApp();
    const url = `courses/my?next=${option}&online=false`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async userCoursesYourOwnPace(option = false) {
    const { $axios } = useNuxtApp();
    const url = `courses/my?online=${option}&history=false`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async userCoursesHistory(option = false) {
    const { $axios } = useNuxtApp();
    const url = `courses/my?history=${option}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async courseAvailability(slug) {
    const { $axios } = useNuxtApp();
    const url = `courses/detail/${slug}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async getFavorites(pathParam) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async getNotificationsFilters() {
    const { $axios } = useNuxtApp();
    const url = 'notifications/filters';
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async getStartupForm(pathParam) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async getNotificationsList() {
    const { $axios } = useNuxtApp();
    const url = 'notifications/list?readed=false';
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async fileDownload(pathParam, slug) {
    const { $axios } = useNuxtApp();
    const url = `/file-downloads/${pathParam}/${slug}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  static async getCoursePath(pathParam) {
    const { $axios } = useNuxtApp();
    const url = `/courses/${pathParam}`;
    const data = await $axios.fetchGet(url);
    return data;
  }

  /* POST */

  static async createProfile(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_IDENTITY_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async resetPassword(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_IDENTITY_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async checkEmailExists(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_IDENTITY_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async sendStartupForm(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async resendRegisterVerificationByEmail(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_IDENTITY_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async editProfile(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async subscribeEvent(slug, body) {
    const { $axios } = useNuxtApp();
    const url = `/courses/enroll/${slug}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async postProfileData(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_IDENTITY_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async setAsReaded(pathParam) {
    const { $axios } = useNuxtApp();
    const url = `courses/${pathParam}/all`;
    const data = await $axios.fetchPost(url);
    return data;
  }

  static async visitCenter(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `/hubspot-forms-api/submitForm/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async checkCaptcha(body) {
    const { $axios } = useNuxtApp();
    const url = `/recaptcha/verify`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async checkFileExist(uri) {
    const { $axios } = useNuxtApp();
    const url = '/courses/material';
    const data = await $axios.fetchPost(url, uri);
    return data;
  }

  static async courseRequest(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `/courses/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async postFavorite(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}`;
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  static async dynamicForms(pathParam, fields) {
    const { $axios } = useNuxtApp();
    const url = `/dynamic-forms/${pathParam}`;
    const data = await $axios.fetchPost(url, { fields });
    return data;
  }

  static async updateLead(context) {
    const { $axios } = useNuxtApp();
    const url = 'marketing/updateLead';
    const data = await $axios.fetchPost(url, { context });
    return data;
  }

  static async surveyPersonalData(body) {
    const { $axios } = useNuxtApp();
    const url = '/courses/registerPoll';
    const data = await $axios.fetchPost(url, body);
    return data;
  }

  /* PUT */

  static async setReadedNotification(notificationId) {
    const { $axios } = useNuxtApp();
    const url = `notifications/setReaded/${notificationId}`;
    const data = await $axios.fetchPut(url, { is_readed: true });
    return data;
  }

  static async notificationsFilters(filters) {
    const { $axios } = useNuxtApp();
    const data = await $axios.fetchPut('notifications/filters', { filters });
    return data;
  }

  static async putStartupForm(pathParam, body) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}`;
    const data = await $axios.fetchPut(url, body);
    return data;
  }

  static async setReadedNotificationAll(group) {
    const { $axios } = useNuxtApp();
    const params = { is_readed: true, ...group };
    const { data } = await $axios.fetchPut(`notifications/setReaded/all`, params);
    return data;
  }

  /* DELETE */

  static async deleteProfileData(pathParam) {
    const { $axios } = useNuxtApp();
    const url = `${process.env.API_IDENTITY_EXT}/${pathParam}`;
    const data = await $axios.fetchDelete(url);
    return data;
  }

  static async deleteFavorite(pathParam, body) {
    const { $axios } = useNuxtApp();
    const qs = Object.keys(body)
      .map(key => `${key}=${body[key]}`)
      .join('&');
    const url = `${process.env.API_PROFILING_EXT}/${pathParam}?${qs}`;
    const data = await $axios.fetchDelete(url);
    return data;
  }
}
