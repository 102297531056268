export default defineNuxtRouteMiddleware(to => {
  const store = useStore();
  const {
    params: { convocatoria }
  } = to;

  if (!store.getters['auth/getByField']('loggedIn')) {
    return navigateTo(`/startups/${convocatoria}`);
  }
});
