import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: () => ({
    indexName: undefined,
    filterAttribute: undefined,
    filter: []
  }),
  getters,
  mutations
};
