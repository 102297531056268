export default {
  config: {
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
    longDateFormat: {
      LT: 'H:mm',
      LTS: 'H:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY H:mm',
      LLLL: 'dddd, D [de] MMMM [de] YYYY'
    },
    calendar: {
      lastDay: '[Ayer]',
      sameDay: '[Hoy]',
      nextDay: '[Mañana]',
      lastWeek: '[El] dddd [pasado]',
      nextWeek: 'dddd',
      sameElse: 'L'
    }
  }
};
