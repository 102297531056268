export default {
  field: {
    // Put the field and the translation
    user: 'User',
    dni_nie: 'DNI/NIE Number'
  },
  validation: {
    alpha: 'The {_field_} may only contain alphabetic characters',
    alpha_num: 'The {_field_} may only contain alpha-numeric characters',
    alpha_dash:
      'The {_field_} may contain alpha-numeric characters as well as dashes and underscores',
    alpha_spaces: 'The {_field_} may only contain alphabetic characters as well as spaces',
    between: 'The {_field_} must be between 0:{min} and 1:{max}',
    confirmed: 'The {_field_} confirmation does not match',
    digits: 'The {_field_} must be numeric and exactly contain 0:{length} digits',
    dimensions: 'The {_field_} must be 0:{width} pixels by 1:{height} pixels',
    email: 'The {_field_} must be a valid email',
    excluded: 'The {_field_} is not a valid value',
    ext: 'The {_field_} is not a valid file',
    image: 'The {_field_} must be an image',
    integer: 'The {_field_} must be an integer',
    length: 'The {_field_} must be 0:{length} long',
    max_value: 'The {_field_} must be 0:{max} or less',
    max: 'The {_field_} may not be greater than 0:{length} characters',
    mimes: 'The {_field_} must have a valid file type',
    min_value: 'The {_field_} must be 0:{min} or more',
    min: 'The {_field_} must be at least 0:{length} characters',
    numeric: 'The {_field_} may only contain numeric characters',
    one_of: 'The {_field_} is not a valid value',
    regex: 'The {_field_} format is invalid',
    required_if: 'The {_field_} is required',
    required: 'Please enter a value in the field {_field_}',
    requiredGeneric: 'Required field.',
    size: 'The {_field_} size must be less than 0:{size}KB',
    url: 'The {_field_} is not a valid URL',
    requiredFamilyName: 'Please enter your {_field_}',
    password: 'Passwords do not match.',
    minPhone: 'The field must be a minimum of {length} characters.',
    maxPhone: 'The field must be a maximum of {length} characters.',
    verify_password: 'Please choose a more secure password.',
    is_password_strength: 'Please choose a more secure password.',
    dni_nie: 'The DNI / NIE entered is not valid.',
    passport: 'The Pasaporte entered is not valid.',
    at_least_1_uppercase: 'At least one uppercase character is needed.',
    at_least_1_lowercase: 'At least one lowercase character is needed.',
    at_least_1_digit: 'At least one number is needed.',
    at_least_1_special: 'At least one special character is needed.',
    select_length: 'More fields have been selected than allowed.'
  }
};
