export default {
  field: {
    // Poner el campo y la traducción
    username: 'Usuario',
    password: 'Contraseña',
    oldPassword: 'Antigua contraseña',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirma tu nueva contraseña',
    givenName: 'Nombre',
    familyName: 'Apellidos',
    email: 'Correo electrónico',
    country: 'País',
    zip: 'Código Postal',
    nif: 'DNI/NIE',
    passport: 'Pasaporte',
    phone: 'Número de teléfono',
    enterprise: 'Nombre de la empresa',
    job: 'Cargo',
    city: 'Provincia',
    state: 'Municipio',
    dni: 'DNI/NIE',
    legal: 'legal',
    feder: 'feder',
    fecha_nacimiento: 'Fecha de nacimiento',
    comarca: 'Comarca',
    codigo_postal: 'Código Postal',
    domicilio: 'Domicilio',
    nacionalidad: 'Nacionalidad',
    SL_otros: 'Otros',
    SL_actualmente_cursando: 'Actualmente cursando',
    situacion_laboral: 'Situación laboral',
    declaracion_responsable: 'Declaración responsable',
    nivel_estudios: 'Nivel de estudios',
    visitCenter: 'Centro que desea visitar',
    visitDay: 'Dia de la visita',
    visitProfile: 'Perfil de la visita',
    visitThematic: 'Tématica de la visita',
    visitReason: 'Motivo de la visita',
    visitLanguage: 'Idioma de la visita',
    visitNumber: 'Número de visitantes',
    visitKindPerson: 'Tipo de visitante',
    visitResidentePerson: 'Eres residente',
    visitComment: 'Observaciones',
    legalCommunications: 'El campo de consentimiento legal es obligatorio'
  },
  validation: {
    alpha: 'El campo {_field_} solo debe contener letras',
    alpha_dash: 'El campo {_field_} solo debe contener letras, números y guiones',
    alpha_num: 'El campo {_field_} solo debe contener letras y números',
    alpha_spaces: 'El campo {_field_} solo debe contener letras y espacios',
    between: 'El campo {_field_} debe estar entre 0:{min} y 1:{max}',
    confirmed: 'El campo {_field_} no coincide',
    digits: 'El campo {_field_} debe ser numérico y contener exactamente 0:{length} dígitos',
    dimensions: 'El campo {_field_} debe ser de 0:{width} píxeles por 1:{height} píxeles',
    email: 'Por favor, introduce un correo electrónico válido.',
    excluded: 'El campo {_field_} debe ser un valor válido',
    ext: 'El campo {_field_} debe ser un archivo válido',
    image: 'El campo {_field_} debe ser una imagen',
    one_of: 'El campo {_field_} debe ser un valor válido',
    integer: 'El campo {_field_} debe ser un entero',
    length: 'El largo del campo {_field_} debe ser 0:{length}',
    max: 'El campo {_field_} no debe ser mayor a 0:{length} caracteres',
    max_value: 'El campo {_field_} debe de ser 0:{max} o menor',
    mimes: 'El campo {_field_} debe ser un tipo de archivo válido',
    min: 'El campo {_field_} debe tener al menos {length} caracteres.',
    min_value: 'El campo {_field_} debe ser 0:{min} o superior',
    numeric: 'Por favor, introduce solo números.',
    regex: 'El formato del campo {_field_} no es válido',
    required: 'Por favor, introduce un valor en el campo {_field_}.',
    requiredGeneric: 'Campo obligatorio.',
    required_if: 'Por favor, introduce tu {_field_}.',
    size: 'El campo {_field_} debe ser menor a 0:{size}KB',
    requiredFamilyName: 'Por favor, introduce tus {_field_}.',
    password: 'Las contraseñas no coinciden.',
    minPhone: 'El campo debe tener un mínimo de {length} caracteres.',
    maxPhone: 'El campo debe tener un máximo de {length} caracteres.',
    verify_password: 'Por favor, elije una contraseña más segura.',
    is_password_strength: 'Por favor, elije una contraseña más segura.',
    dni_nie: 'El DNI/NIE introducido no es válido.',
    passport: 'El Pasaporte introducido no es válido.',
    at_least_1_uppercase: 'La contraseña debe tener al menos una letra mayúscula.',
    at_least_1_lowercase: 'La contraseña debe tener al menos una letra minúscula.',
    at_least_1_digit: 'La contraseña debe tener al menos un número.',
    at_least_1_special: 'La contraseña debe tener al menos un caracter especial.',
    select_length: 'Se han seleccionado más campos de los permitidos, el máximo son {length}',
    check: 'Es obligatorio marcar la casilla',
    emailPass: 'La contraseña no puede contener parte del email',
    regexAzure:
      'La contraseña debe tener al menos 8 caracteres. Requiere 2 de estas 4 condiciones: minúsculas, mayúsculas, números o símbolos.'
  }
};
