import { useWindowSize } from '@vueuse/core';

export const useBreakpoints = () => {
  const { windowWidth } = useWindowSize();
  const state = reactive({
    mqSm: null,
    mqMd: null,
    mqLg: null,
    mqXl: null,
    mqXxl: null,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false
  });
  const { mqSm, mqMd, mqLg, mqXl, mqXxl, sm, md, lg, xl, xxl } = toRefs(state);
  const matchMediaSm = e => (sm.value = !!e.matches);

  // md
  const matchMediaMd = e => (md.value = !!e.matches);

  // lg
  const matchMediaLg = e => (lg.value = !!e.matches);

  // xl
  const matchMediaXl = e => (xl.value = !!e.matches);

  // xxl
  const matchMediaXxl = e => (xxl.value = !!e.matches);

  onMounted(() => {
    mqSm.value = window.matchMedia('(min-width: 640px)');
    mqMd.value = window.matchMedia('(min-width: 768px)');
    mqLg.value = window.matchMedia('(min-width: 1024px)');
    mqXl.value = window.matchMedia('(min-width: 1280px)');
    mqXxl.value = window.matchMedia('(min-width: 1536px)');

    // Breakpoint prefix sm
    matchMediaSm(mqSm.value);
    mqSm.value.addListener(matchMediaSm);
    // Breakpoint prefix md
    matchMediaMd(mqMd.value);
    mqMd.value.addListener(matchMediaMd);
    // Breakpoint prefix lg
    matchMediaLg(mqLg.value);
    mqLg.value.addListener(matchMediaLg);
    // Breakpoint prefix xl
    matchMediaXl(mqXl.value);
    mqXl.value.addListener(matchMediaXl);
    // Breakpoint prefix xxl
    matchMediaXxl(mqXxl.value);
    mqXxl.value.addListener(matchMediaXxl);
  });
  return {
    mqSm,
    mqMd,
    mqLg,
    mqXl,
    mqXxl,
    sm,
    md,
    lg,
    xl,
    xxl,
    windowWidth
  };
};
