export default defineNuxtRouteMiddleware(async () => {
  const { $store, $i18n } = useNuxtApp();
  if (
    !$store.getters['strapi/getByField']('common') &&
    !$store.getters['strapi/getByField']('process')
  ) {
    await $store.dispatch('strapi/getCommons', $i18n.locale.value);
    await $store.dispatch('strapi/getProcess', $i18n.locale.value);
  }
});
