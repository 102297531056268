/* eslint-disable max-lines */
import veeValidate from './vee-validate';

export default {
  ...veeValidate,
  previewMode: {
    text: 'Vista previa del borrador',
    button: 'Haga clic aquí para actualizar'
  },
  message: {
    error: {
      page: {
        title: 'Ups... ¡Algo salió mal!',
        undefined:
          'Ocurrió un error inesperado, por favor inténtelo más tarde o entre en contacto con nosotros.',
        404: 'Lo sentimos, pero la página que estaba buscando no existe.',
        401: 'Acceso restringido.'
      },
      errorFile: 'Este documento no existe o ya ha expirado. Lamentamos las molestias.',
      popups: 'Asegúrate de tener los pop-ups emergentes habilitados',
      undefined:
        'Ocurrió un error inesperado, por favor inténtelo más tarde o entre en contacto con nosotros.',
      payload: 'Faltan datos por introducir.'
    },
    success: {
      ok: 'Te hemos ampliado el plazo para realizar el curso.'
    },
    alert: {
      pwa: 'Añade Plataforma Tierra\nen la pantalla de tu dispositivo',
      pwaIos: {
        first: 'Tierra en su iPhone: toque',
        second: 'y luego <span class="text-gray">Añadir a pantalla de inicio<span>'
      },
      maintenance:
        'El próximo lunes 26 de septiembre vamos a realizar labores de mantenimiento, y </br> Plataforma Tierra no estará disponible desde las 15h, un máximo de 3 horas. </br>¡Sentimos las molestias!'
    },
    callback: {
      signIn: 'Abriendo las puertas...',
      signOut: 'Cerrando las puertas...',
      passwordReset: 'Restablece tu contraseña...',
      redirecting: 'Redireccionando...',
      cancelEnteringInfo: 'Ha cancelado la introducción de información...',
      checkSignIn: 'Verificando el inicio de sesión...'
    }
  },
  header: {
    burguerNav: 'Abrir menú',
    ourTools: 'Mis herramientas',
    ourToolsResponsive: 'Herramientas'
  },
  auth: {
    welcomeText: 'Bienvenido,',
    accountAlreadyExists: 'Parece que ya tienes cuenta',
    subtitleAccountAlreadyExist: 'En 3 segundos te redirigiremos al inicio sesión',
    createAccount: 'Crear cuenta',
    subtitleCreateAccount: 'Vamos a comprobar tu correo electrónico',
    userRegistered: '¡Ya estás registrado!',
    buttons: {
      hasAnAccount: '¿Ya tienes una cuenta?',
      hasNotAnAccount: '¿Aún no tienes una cuenta?',
      login: 'Iniciar sesión',
      logout: 'Cerrar sesión',
      preferences: 'Obtener preferencias del usuario',
      unsubscribe: 'Darse de baja',
      register: 'Regístrate',
      registerHere: 'Regístrate aquí',
      signUp: 'Suscribirme',
      signIn: 'Accede',
      notAccount: 'No tengo cuenta',
      editProfile: 'Editar mi perfil',
      close: 'Cerrar',
      next: 'Siguiente',
      back: 'Atrás',
      continue: 'Continuar',
      accept: 'Aceptar',
      cancel: 'Cancelar',
      previous: 'Anterior',
      cxtierra: 'Activar CXTierra',
      startup: 'Empezar mi startup'
    }
  },
  updatePassword: {
    message:
      'La contraseña se ha restablecido con éxito. Por favor, inicia sesión con tu nueva contraseña.'
  },
  home: {
    staffPicks: 'Te recomendamos',
    lastPosts: {
      title: 'Nuevas Publicaciones',
      link: 'Conoce nuestra BiblioTierra',
      label: 'Actualidad',
      market: 'Análisis de Mercados',
      newCoyuntura: 'Coyuntura de Mercados Agroalimentarios'
    },
    subsectors: {
      changeSubsectors: 'Cambiar tus subsectores aquí',
      all: 'Todos'
    },
    sectors: {
      label: 'Sectores'
    }
  },
  innovation: {
    lastBooks: {
      title: 'Nuevas Publicaciones'
    },
    markets: 'Informes de mercados'
  },
  formation: {
    detail: {
      webinarSesions: 'Sesiones del evento:',
      whatContent: '¿Cuál es el contenido de este {event}?',
      whatContentHybrid: '¿Cuál es el contenido de este evento presencial / webinar?',
      whoTeach: '¿Quién imparte este {event}?',
      whoTeachHybrid: '¿Quién imparte este evento presencial / webinar?',
      duration: 'Duración: ',
      organizers: 'Organizadores',
      collaborators: 'Colaboradores',
      coursesRelated: 'Cursos relacionados',
      session_onlinecourse_title: 'Programa del curso online',
      session_onlinecourse_duration: 'A tu ritmo',
      session_onlinecourse_acessurl: 'Recuerda que puedes acceder al curso en mis eventos',
      classroomCourse: 'Evento presencial',
      onlineCourse: 'Curso online',
      hybridCourse: 'Evento presencial / webinar',
      whereCelebrated: '¿Dónde se celebra?',
      progress: 'Progreso'
    },
    courses: {
      eventsSerie: 'Más eventos de esta serie',
      serie: 'Serie: ',
      inscription: {
        signUpNow: 'Inscribirme ahora',
        signUpHere: 'Inscríbete aquí',
        requestExtend: 'Solicitar ampliación',
        signUpNowLoggedIn: 'Inscríbete en 1-click',
        successEnrollment: 'Inscripción confirmada',
        requestCertificate: 'Pedir el certificado',
        certificateSub: 'Solicitud de certificado',
        askCertificateText:
          'Equipo de Formación de Plataforma Tierra,\n\nTras finalizar el curso "{course}", yo, {name}, con DNI {dni}, quiero solicitar el certificado correspondiente.\n\nUn saludo y muchas gracias,\n\n{name}.\n\n',
        requestCertificateText:
          'Haz clic en el botón “Pedir el certificado” para ponerte en contacto con nosotros',
        titleLink: 'Tu enlace para el día del evento',
        errorLink: '*Este enlace es personal, por favor no se lo reenvíes a nadie',
        copyLink: 'Copiado al portapapeles',
        addToCalendar: 'Añade a tu calendario:',
        session: 'Sesión',
        inscriptionNoOpen: 'La inscripción todavía no está abierta',
        interest: '¿Te interesa este curso?',
        interestPastEvent: '¿Te interesa este contenido?',
        checkMaterialPastEvent: 'Consulta el material o ponte en contacto con nosotros',
        contactUs:
          'Contacta con nosotros a través del chat, te avisaremos cuando se abra la inscripción',
        confirm: '¿Quieres confirmar tu inscripción al curso?',
        confirmPastEvent: 'Este curso es una iniciativa del FEDER',
        federDescription:
          'Cajamar Innova está cofinanciada al 80 % por el Fondo Europeo de Desarrollo Regional (FEDER) dentro del Programa Operativo Plurirregional 2014-2020.',
        feder: 'He sido informado que este programa formativo es una iniciativa FEDER.',
        enrolled: '¡Ya estás inscrito!',
        howToGet: 'Cómo llegar',
        courseInformation: 'Información del curso',
        beforeStartTime: 'Ha de estar 15 minutos antes de la hora de inicio',
        necessaryCompleteProfile:
          'Para inscribirse en el curso es necesario completar los datos de su perfil',
        urlAccessClasslifeCourse: 'Enlace de acceso a curso',
        noUpcomingEvent: '¡No tienes ningún evento próximo!',
        whereItIsCelebrated: '¿Dónde se celebra?',
        legalConsent: 'Consentimiento legal',
        errorEnrolled:
          'Ha habido un error en la inscripción a este curso. Hemos notificado al equipo la incidencia. En cuanto esté resuelto nos pondremos en contacto contigo, muchas gracias.'
      },
      calendar: {
        outlook: 'Outlook',
        gmail: 'Gmail',
        ical: 'iCalendar (.ics)',
        or: ' o ',
        actions: {
          next: 'siguiente',
          previous: 'anterior'
        },
        emptySector: 'Actualmente no hay eventos previstos del {value}',
        empty: 'Actualmente no hay eventos previstos',
        subsector: 'subsector',
        sector: 'sector'
      },
      material: {
        seeVideo: 'Ver vídeo',
        downloadPdf: 'Descargar PDF',
        equipmentTitle: 'Material y grabación del webinar',
        materialDownload: 'Material de descarga',
        notAvaible: 'El material todavía no está disponible',
        textNotAvaible:
          'Tras finalizar la formación, subiremos el material del curso y las grabaciones a la mayor brevedad posible. ¡Gracias!',
        loggedOut: 'Accede para ver el material',
        textLoggedOut:
          'Haz clic en el botón “Accede al material” y tendrás acceso a las grabaciones y todo el contenido del curso.',
        getCertificate: '¿Quieres obtener el certificado?'
      },
      modalDni: {
        title: 'Pedir certificado',
        description:
          'Por favor, rellena tu DNI para poder proceder a la solicitud de tu certificado',
        link: 'Ir a la solicitud'
      }
    }
  },
  detail: {
    contentRelational: 'Contenido relacionado',
    interest: 'Te puede interesar',
    reportVersions: 'Otras ediciones de este informe',
    notificationReport: ' Existe una edición actualizada de este informe.',
    seeHere: 'Ver aquí.',
    licenseUnder: 'Esta obra está bajo una',
    creativeCommons:
      'Licencia Creative Commons Atribución 4.0 Internacional. Se permite la reproducción total o parcial del contenido siempre que se cite la fuente original.',
    authDownload: 'Inicia sesión para descargar este documento'
  },
  experimentalCenters: {
    metaTitle: 'Visita',
    ourCenters: {
      discover: {
        title: 'Conoce nuestros\nCentros Experimentales',
        description:
          'En los Centros Experimentales de Cajamar abordamos proyectos de investigación aplicada en el desarrollo de innovadoras tecnologías, en colaboración permanente con decenas de empresas, universidades y centros tecnológicos.'
      },
      titleUp: 'Nuestro Ecosistema de Innovación',
      title:
        '<span class="text-blue">Innovando en</span> TECNOLOGÍA SOSTENIBLE <span class="text-blue">desde</span> 1975',
      content: {
        exploitation: 'Referentes en agricultura intensiva mediterránea',
        process: 'Atención a las necesidades reales de todos los agentes de la cadena',
        university: 'Colaboración con empresas, universidades y centros tecnológicos',
        water: 'Impulsando el emprendimiento en nuevas tecnologías del agua',
        mapPinAlt: 'Más de 20 hectáreas destinadas a I+D+i en Valencia y Almería'
      }
    },
    confirmButton: 'De acuerdo, espero el correo de confirmación',
    agroChain: {
      title: 'Creando valor para toda la cadena agroalimentaria',
      descriptions: {
        first:
          'Identificamos nuevas tecnologías aplicadas a la eficiencia y la sostenibilidad de nuestro sector agroalimentario. Y te presentamos informes de síntesis para que estés al día de las últimas tendencias y aplicaciones del mercado',
        second:
          'Identificamos nuevas tecnologías aplicadas a la eficiencia y la sostenibilidad de nuestro sector agroalimentario. Y te presentamos informes de síntesis para que estés al día de las últimas tendencias y aplicaciones del mercado'
      },
      checks: {
        first: {
          title: 'Descargar documentos',
          description:
            'Identificamos nuevas tecnologías aplicadas a la eficiencia y la sostenibilidad de nuestro sector '
        },
        second: {
          title: 'Informes descargables',
          description:
            'Identificamos nuevas tecnologías aplicadas a la eficiencia y la sostenibilidad de nuestro sector '
        },
        third: {
          title: 'Start  ups Agro',
          description: 'Identificamos nuevas tecnologías aplicadas a la eficiencia  '
        }
      },
      visitLink: 'Ven a visitar {center}'
    },
    form: {
      language: {
        spanish: 'Español',
        english: 'Inglés'
      },
      visitProfile: {
        informative: 'Divulgativo',
        scientific: 'Científico',
        technical: 'Técnico'
      },
      thematic: {
        general: 'General',
        bioeconomy: 'Bioeconomía',
        agrosustainability: 'Agrosostenibilidad',
        nutritionHealth: 'Alimentación y salud',
        greenhouse: 'Tecnología de invernaderos'
      },
      reason: {
        advice: 'Asesoramiento',
        informative: 'Informativo',
        followUp: 'Seguimiento',
        meeting: 'Reunión'
      },
      kindPerson: {
        particular: 'Particular',
        enterprite: 'Empresa',
        public: 'Organismo Público',
        school: 'Colegio/Instituto',
        university: 'Universidad',
        Association: 'Asociación',
        investigationCenter: 'Centro de investigación'
      },
      ResidenciaPerson: {
        noresiden: 'No Residente',
        residente: 'Residente'
      }
    },
    projects: {
      centerAndTeam: 'Centro y equipo del proyecto',
      responsible: 'Responsable del proyecto',
      cajamarTeam: 'Equipo Cajamar',
      sponsors: 'Patrocinadores',
      collaborators: 'Colaboradores',
      coordinator: 'Coordinador',
      participants: 'Participantes',
      members: 'Miembros',
      content: 'Contenido',
      scopeAndReach: 'Ámbito y alcance',
      scope: 'Ámbito del proyecto',
      financing: 'Tipo de financiación',
      workLine: 'Línea de trabajo',
      taxonomy: 'Taxonomía',
      logo: 'Logotipo del proyecto',
      scopeList: {
        autonomico: 'Autonómico',
        europeo: 'Europeo',
        nacional: 'Nacional'
      },
      taxonomyList: {
        economia_circular: 'Economía circular',
        mitigacion_y_adaptacion_al_cambio_climatico: 'Mitigación y adaptación al cambio climático',
        perdida_de_la_biodiversidad: 'Perdida de la biodiversidad',
        prevencion_de_la_contaminacion: 'Prevención de la contaminación',
        proteccion_de_los_recursos_hidricos: 'Protección de los recursos hídricos'
      },
      financingList: {
        cajamar_innova: 'CAJAMAR INNOVA',
        colaboracion: 'Colaboración',
        contrato_con_empresa: 'Contrato con empresa',
        propio: 'Propio',
        financiacion_publica: 'Financiación pública',
        subcontrato: 'Subcontrato'
      },
      workLineList: {
        agrosostenibilidad: 'Agrosostenibilidad',
        alimentacion_y_salud: 'Alimentación y salud',
        bioeconomia: 'Bioeconomía',
        tecnologia_de_invernaderos: 'Tecnología'
      },
      projectID: 'Proyecto I+D',
      projectsID: 'Proyectos I+D',
      date: { start: 'Fecha inicio', end: 'Fecha fin', continue: 'Continuo' }
    }
  },
  markets: {
    favorites: 'Favoritos',
    clickInTheIcon: 'Haz clic en el icono ',
    toYourFavorites: 'para agregar a tus favoritos',
    lastIndicators: 'Indicadores de',
    checkReports: 'Consulta aquí los informes de mercado del sector',
    someReports: 'Informes de mercado',
    someReportsNew: 'Informes Publicados',
    lastReports: 'Últimos informes públicados',
    lastInternationalTrade: 'Comercio Exterior',
    lastReport: 'Último informe',
    mainPrices: 'Principales precios en origen',
    emptyReports: 'Lo sentimos, en estos momentos no hay informes disponibles',
    emptyKpis: 'Lo sentimos, en estos momentos no hay indicadores disponibles',
    tooltipSectors:
      'Si quieres tener acceso a más productos, cambia tus sectores de interés en "Editar mi perfil"',
    imRegistered: 'Ya estoy registrado:',
    privateCommentsTitle: 'Contenido para usuarios registrados',
    privateComments: 'Accede a este y otros muchos contenidos registrándote gratuitamente',
    privateContent: '¿Quieres acceder a todo el contenido disponible?',
    privateContentTitle: '¡Nos encanta que te interese nuestro contenido!',
    privateContentParaph:
      'Si quieres acceder a todo el contenido, regístrate o inicia sesión. Podrás elegir tus sectores de interés en tu perfil',
    changeSubsectorModalTitle: 'El subsector seleccionado no está entre tus preferencias',
    changeSubsectorModalParaph:
      'No te preocupes, para tener acceso a todo el contenido de los subsectores que más te interesen, puedes añadirlos en tu perfil.',
    addSector: 'Añadir sector',
    addSubsector: 'Añadir subsector',
    indicators:
      'Reunimos para ti más de 700 indicadores de precio, producción y mercado de 56 productos agroalimentarios que actualizamos permanentemente. ',
    indicatorsLoginHelperText: ', para acceder al detalle de cada indicador.',
    indicatorsLogin:
      'Reunimos para ti más de 700 indicadores de precio, producción y mercado de 56 productos agroalimentarios que actualizamos permanentemente. ',
    indicatorsLoginShort: 'Cambia tus sectores preferidos aquí',
    titleAlertSubsector: 'Elige los subsectores que más te interesan',
    paraphAlertSubsector:
      '<p> Para configurar la nueva sección de <b>Mercados</b> según tus intereses, puedes seleccionar <b>hasta 8 subsectores</b> simultaneamente <p> Más adelante podrás cambialos en <b>Editar mi perfil</b>, en el menú que se despliega al pulsar tu nombre </p>',
    kpiBanner: 'Personaliza estos indicadores iniciando sesión aquí',
    title: 'Indicadores Sectoriales',
    descriptionMarkeSubsector:
      'Aquí encontrarás los últimos informes de mercado relacionados con el sector seleccionado',
    titleIcons: '¿Qué significan los iconos de cada sector ?',
    descriptionIcons: 'Te mostramos un pequeño resumen de cada indicador:',
    note: 'Nota Semanal de Coyuntura',
    previousNews: 'Ver noticias anteriores',
    newContinue: 'Seguir leyendo',
    coyuntura: 'Coyuntura',
    indicator: 'Indicadores',
    informes: 'Informes'
  },
  notifications: {
    empty: 'No tienes notificaciones nuevas',
    btn: 'Ir a la página',
    newNotification:
      'Tienes {counter} nueva notificación. | Tienes {counter} nuevas notificaciones.',
    managementNotifications: 'Gestionar mis notificaciones',
    showMore: 'Mostrar menos',
    removeAll: 'Borrar todas',
    notifications: 'Notificaciones',
    loginMessage: 'Inicia sesión para ver tus nuevas notificaciones'
  },
  seeker: {
    nav: {
      myEvents: 'Mis eventos',
      notifications: 'Notificaciones',
      saved: 'Guardados',
      download: 'Descargas',
      startup: 'Mi Startup'
    },
    title: 'Buscador',
    select: {
      label: 'Filtrar la búsqueda por',
      placeholder: 'Buscar en Tierra'
    }
  },
  ui: {
    button: {
      processing: 'Procesando',
      seeMoreCourses: 'Ver más cursos',
      seeMorePosts: 'Ver más artículos',
      seeMoreHits: 'Mostrar más resultados',
      back: 'Volver',
      save: 'Guardar',
      saveChanges: 'Guardar cambios',
      saved: 'Guardado',
      share: 'Compartir',
      download: 'Descargar',
      clear: 'Limpiar filtros',
      showAllFilters: 'Mostrar los filtros',
      deleteAllFilters: 'Borrar todos los filtros',
      resetPassword: 'Recuperar',
      updatePassword: 'Actualizar contraseña',
      recoveryPassword: '¿Has olvidado tu contraseña?',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      continue: 'Continuar',
      send: 'Enviar',
      resend: 'Reenviar',
      sent: 'Enviado',
      visit: '¿Quieres visitarnos?',
      accessToCourse: 'Acceder al curso',
      exploreEvents: 'Explorar eventos',
      accessToWebinar: 'Acceder al webinar',
      toAccess: 'Acceder',
      toExplorer: ' Explorar',
      add: 'Añadir',
      accessMaterial: 'Accede al material',
      registerMaterial: 'Accede al material',
      seeComments: 'Ver comentarios',
      allReports: 'Todos los informes',
      goProfile: 'Ir a mi perfil',
      seeLater: 'En otro momento',
      accessToCXTierra: 'Accede al Cuaderno Digital',
      registration: 'Dar de alta mi proyecto'
    },
    label: {
      readMore: 'Leer más',
      learnMore: 'Conocer más',
      seeMore: 'Ver más',
      seeAll: 'Ver todos',
      seeLess: 'Ver menos',
      highlighted: 'Destacado',
      highlight: 'Hoy destacamos',
      showOurCalendar: 'Ver toda la Agenda',
      showMoreEvents: 'Ver más eventos',
      showMoreMarket: 'Toda la información de mercados',
      ourCalendar: 'Nuestra Agenda',
      joinUsMobile: 'Únete',
      joinUsDesktop: 'Únete ahora',
      calendar: 'Agenda',
      checkWeatherForecast: 'Consulta la previsión del tiempo en tu localización exacta',
      videoLibrary: 'Nuestra videoteca',
      recommended: 'Recomendado',
      lastPlaces: 'Últimas plazas',
      orderBy: 'Ordenar por: ',
      relatedCourses: 'Cursos relacionados que te pueden interesar',
      subscribeEnd: 'La inscripción termina en',
      subscribeFinished: 'La inscripción del curso ha terminado.',
      courseFinished: 'El curso ha finalizado.',
      expiredCourse: 'El plazo para realizar el curso ha finalizado.',
      price: 'Precio:',
      free: 'Gratuito',
      topic: 'Tema:',
      level: 'Nivel:',
      language: 'Idioma:',
      courseType: 'Tipo de curso:',
      date: 'Fecha',
      aisStats:
        'Mostrando <strong>{currentItem} - {lastItem}</strong> de <strong>{nbHits} resultados</strong>',
      aisStatsNotResult: '<strong>Sin resultados</strong>',
      aisStatsNotResultGeneric: 'No hemos encontrado resultados relacionados con tu búsqueda',
      aisHitsPerPage: '{value} resultados por página',
      fiterBy: 'Filtrar por:',
      searchHelp:
        'Presione <strong>↑↓</strong> para navegar, <strong>enter</strong> para seleccionar, <strong>esc</strong> para descartar',
      source: 'Fuente',
      yourOwnPace: 'A tu ritmo',
      theCourseStartsIn: 'El curso empieza en',
      theCourseExpiredIn: 'El curso caduca en',
      expand: 'Ampliar',
      close: 'Cerrar',
      finishedCourse: 'El evento ha finalizado. Próximamente nueva edición.',
      subscribeOurNewsletter: 'Suscríbete a nuestra Newsletter semanal',
      subscribeNewsletter: 'Suscríbete al Boletín Semanal',
      goMarket: 'Ir a Mercados',
      registerCallForProposals1: 'Recuerda que para participar',
      registerCallForProposals2: 'en Plataforma Tierra.'
    },
    placeholder: {
      searchVideo: 'Buscar vídeo...',
      searchBiblioTierra: 'Buscar en la biblioteca...',
      searchMarketReport: 'Buscar en informes...',
      searchDocuments: 'Buscar en Documentos',
      searchCourses: 'Buscar curso aquí...',
      searchArticles: 'Buscar artículo aquí...',
      searchAutorBlog: 'Buscar en el blog de {autor}',
      searchMarket: 'Buscar en ',
      searchInfograms: 'Buscar indicador aquí...',
      username: 'Indica tu correo electrónico',
      password: 'Introduce tu contraseña',
      email: 'Correo electrónico',
      nif: 'Número de DNI o NIE',
      searchHere: 'Buscar aquí',
      searchLocation: 'Buscar Localización',
      IDpassport: 'Número de pasaporte'
    }
  },
  forms: {
    label: {
      resident: 'Residente en España',
      noResident: 'No residente',
      yes: 'Sí',
      no: 'No',
      close: 'Cerrar'
    },
    errors: {
      signUp: {
        400: 'El email o la contraseña es incorrecta',
        401: 'La contraseña no puede contener parte del correo electrónico',
        403: 'Revise su bandeja de entrada porque el correo electrónico ya está registrado',
        409: 'La dirección de correo electrónico que has introducido ya existe'
      },
      subscribeEvent: {
        1001: 'Ha ocurrido un problema con la sesión, por favor cierra sesión e inténtalo de nuevo. Si el error persiste, ponte en contacto con nosotros.',
        1005: 'Ha ocurrido un error, asegúrese de que todos los campos estén cumplimentados',
        1006: 'Ha ocurrido un error, El usuario ya está inscrito a este evento',
        8001: 'Hemos detectado que ya tienes una cuenta registrada en Plataforma Tierra. Vamos a redirigirte a la página de login para que puedas continuar con la inscripción al curso. Muchas gracias.',
        8002: 'Tu correo aún no está verificado. Por favor completa tu registro antes de continuar.',
        8003: 'No se ha realizado tu inscripción ya que el evento ha finalizado. Si tienes alguna duda, por favor, ponte en contacto con nosotros.'
      },
      throwError:
        'Ha ocurrido un error, por favor vuelve a intentarlo en unos minutos. Si el error persiste, ponte en contacto con nosotros.'
    },
    claims: {
      veryStrong: 'Muy segura',
      strong: 'Segura',
      weak: 'Poco segura',
      notStrong: 'Nada segura',
      minimumPasswordCharacters: 'La contraseña debe contener mínimo 8 caracteres.'
    },
    filter: {
      label: 'Filtrar por Categoría',
      buttonSaveFilter: 'Filtrar',
      clearFilter: 'Limpiar'
    },
    register: {
      legends: {
        areCustomer: '¿Eres cliente del Grupo Cooperativo Cajamar?',
        chooseActivity: 'Elige un sector de actividad',
        chooseSubsector: 'Elige los subsectores agro de tu interés',
        chooseSubsector_small: '(Máximo 8)',
        sectorRemains: 'Quedan',
        legalWarning: 'Aviso legal - condiciones',
        cxtierra: 'Consentimiento a la inscripción de CXTierra',
        personal: 'Datos personales',
        emailValidatedTitle: ' Email validado correctamente ¡Ya estás suscrito!',
        emailValidatedDescription:
          ' Bienvenido a “Plataforma TIERRA” creado por y para profesionales de toda la cadena del sector agroalimentario desde su origen.',
        goToHome: 'Ir a la página principal',
        zip: 'Código postal',
        phoneCx:
          'Este campo es requerido con la finalidad de ofrecerle un mejor servicio y soporte técnico en el manejo y explotación de CXTierra'
      },
      activitySector: 'Sector de actividad',
      dataProtection:
        'Para más detalles sobre el tratamiento de tus datos, consulta nuestra Política de Protección de Datos. Al hacer clic en Enviar,aceptas que Plataforma Tierra almacene y procese la información personal suministrada arriba para proporcionarte el servicio solicitado.',
      iRead: 'He leído el ',
      lastDatas: 'Completa los siguientes datos para finalizar la inscripción en el curso.',
      legalWarning: 'Aviso legal',
      weeklyNewsletter: 'Quiero recibir el boletín semanal',
      messageConfirmationEmail: 'Te enviaremos email de confirmación',
      descriptionZip: 'Te pedimos tu código postal para fines estadísticos',
      sectors: {
        fruit: 'Fruta',
        livestock: 'Ganadería'
      }
    },
    steps: {
      profile: 'Perfil',
      preferences: 'Preferencias',
      personalInformation: 'Datos personales',
      signIn: 'Iniciar sesión',
      user: 'Usuario',
      cxtierra: 'CXTierra',
      startup: 'Startup'
    },
    success: {
      general: 'Iniciar sesión',
      cxtierra: 'Ir a CXTierra',
      startup: 'Iniciar sesión'
    },
    newsletter: {
      successMessage: 'Te has suscrito a la newsletter'
    }
  },
  links: {
    signIn: 'accede',
    signUp: 'suscribete',
    welcomePage: 'gracias-por-suscribirte',
    back: 'Atrás',
    regulatoryBases:
      'Consulta aquí las Bases Reguladoras para la Presentación a los Programas de Aceleración'
  },
  breadcrumbs: {
    home: 'Home',
    innovacion: 'Innovación',
    mercados: 'Mercados',
    formacion: 'Formación',
    centros: 'Centros Experimentales',
    aboutUs: 'Quiénes somos',
    news: 'Actualidad',
    ourTools: 'Herramientas',
    community: 'Comunidad',
    startups: 'Startups',
    announcement: 'Convocatoria',
    comment: 'Nota Semanal de Coyuntura'
  },
  modal: {
    dafault: {
      trigger: 'Abrir modal'
    }
  },
  formInnova: {
    textConfirm:
      'Estás a punto de enviar tus datos. Una vez que hagas clic en "Enviar", no podrás realizar más cambios en el formulario. ¿Estás seguro de querer proceder?',
    textInfo:
      'El formulario ha sido enviado con éxito. Ahora se encuentra en modo de solo lectura y no se pueden realizar modificaciones ni enviar de nuevo. Si necesitas realizar algún cambio o tienes alguna consulta, por favor, ponte en contacto con nuestro equipo de soporte.',
    textFinish: 'Tu formulario ha sido enviado correctamente.',
    textDelete:
      'A continuación se borrarán todos los datos introducidos para la candidatura. ¿Seguro que desea proceder?',
    deleteData: 'Borrar datos'
  },
  accesibility: {
    readTime: 'Tiempo estimado de lectura'
  },
  banners: {
    signUpPlatform: {
      customizedTraining: '¿Quieres una formación a medida organizada por expertos del sector?',
      subscribeOurPlatform:
        'Registrate en nuestra plataforma y disfruta de acceso ilimitado a cursos, eventos, descarga de contenido, publicaciones y mucho más.',
      subscribe: 'Inscribirme'
    },
    privateContent: {
      titleLoggedIn: 'Este artículo contiene un resumen interactivo del informe de mercado.',
      titleLoggedOut: 'Este artículo contiene un informe de mercado interactivo.',
      linkLoggedIn: 'Visualizar resumen',
      linkLoggedOut: 'Acceder al resumen'
    },
    ourFormula: {
      formula: 'La fórmula de nuestro',
      adn: 'Adn agro',
      waterSunEarth: 'El Agua, el sol, la\ntierra...',
      people: 'Y las personas'
    },
    initSession: {
      firstText: '¿Sabes que puedes personalizar el contenido de nuestra web?',
      secondText:
        'Si <b>inicias sesión</b> podrás ver información específica según tus sectores de interés y tendrás <b>acceso gratuito</b> a herramientas digitales, indicadores de mercado y publicaciones',
      link: 'Personalizar'
    }
  },
  strapiDataMap: {
    language: {
      spanish: 'español',
      english: 'inglés'
    },
    beginner: 'principiante',
    intermediate: 'intermedio',
    hibrido: 'Presencial Webinar',
    advanced: 'avanzado',
    enterprise: 'empresarial',
    specialization: 'especialización',
    online: 'curso on-line',
    onsite: 'presencial',
    presencial: 'presencial',
    workshop: 'workshop',
    webinar: 'webinar',
    cxtierra: 'CXTierra',
    soon: 'Próximamente',
    false: 'No',
    true: 'Si',
    hybrid: 'hibrido',
    presentation: 'presentación',
    visita_virtual: 'visita virtual',
    past_event: 'ya realizados',
    category_facets: {
      all: 'Todo'
    },
    content: {
      books: 'Libros',
      observatory: 'Observatorio Tecnológico',
      file: 'Archivos',
      'market-report': 'Informes'
    }
  },
  author: {
    ourAuthors: 'Nuestros autores',
    authorNameDefault: 'Autor',
    authorDescriptionDefault: 'Autor',
    subscribeToBenefit: 'Suscríbete para beneficiarte de los servicios en Plataforma Tierra',
    recognition:
      'Tenemos a los autores más prestigiosos, desde dueños de cooperativas y grandes empresas, hasta académicos, políticos, y gurús de charlas TedEx.',
    subscribeForFree: 'Suscríbete gratis',
    authorPublications: 'Publicaciones de este autor',
    autorArticlesAndPublications: 'Artículos y publicaciones de este autor',
    communityBlog: 'Espacio personal en la Comunidad de Plataforma Tierra',
    autorEvents: 'Eventos de este autor',
    authorCourses: 'Cursos de este instructor',
    authorBooks: 'Libros de este autor',
    authorListsTitle: 'Las voces de Plataforma Tierra',
    participan: 'Participan',
    moderator: 'Modera'
  },
  metaTags: {
    metaTitle: {
      author: 'Publicaciones de {name} en {appName}'
    }
  },
  indexName: {
    all: 'Todo',
    articles: 'Artículos',
    events: 'Eventos',
    authors: 'Autores',
    posts: 'Posts',
    publications: 'Publicaciones',
    'video-library': 'Videoteca',
    coming: 'Eventos próximos',
    allMyEvents: 'Todos mis eventos',
    concerning: 'Eventos a tu ritmo',
    history: 'Histórico de eventos',
    myArticles: 'Artículos',
    myVideos: 'Vídeos',
    myProjects: 'Proyectos I+D',
    myPosts: 'Posts',
    calls: 'Agrotech 2024',
    'market-comments': 'Nota Semanal de Coyuntura'
  },
  book: {
    modal: {
      textModal:
        'Para descargar documentos es necesario estar registrado. A continuación, se le redigirá al registro y luego podrá descargar el documento haciendo click de nuevo.',
      confirm: 'Ya puedes descargar el archivo'
    },
    download: {
      helpText: 'Para descargar esta publicación hay que <b>iniciar sesión</b>.',
      registerText: 'Si no tienes usuario,',
      registerLink: 'regístrate aquí.'
    }
  },
  profile: {
    title: 'Mi perfil',
    description:
      'Completar todos los datos de tu perfil (campos obligatorios y opcionales) te permitirá inscibirte a cursos eventos, descargar libros y disfrutar de otros beneficios de forma directa.',
    profileTitle: 'Mis datos personales',
    interestTitle: 'Mis Preferencias',
    notificationTitle: 'Mis notificaciones',
    notificationLabel: 'Elige qué notificaciones estás interesado en recibir',
    sectorsTitle: 'Sectores de interés',
    selectAll: 'Marcar todos',
    sectorsSelected: 'Seleccionados:',
    sectorsMax: 'Máximo alcanzado',
    emailPassTitle: 'Email y contraseña',
    removeAccount: 'Eliminar perfil',
    sectorsInfo:
      'Las áreas de trabajo que elijas serán tus preferencias para las secciones de mercados y actualidad. Escoge un máximo de 8',
    areCajamarCustomer: '¿Eres cliente de Cajamar?',
    changePassword: 'Cambiar contraseña',
    forgotPassword: '¿No te acuerdas de tu contraseña?',
    successFullChangeUserProfile: 'Tus datos han sido actualizados correctamente.',
    errorFullChangeUserProfile:
      'Ha ocurrido un error, por favor vuelve a intentarlo en unos minutos. Si el error persiste, ponte en contacto con nosotros.',
    addImageButton: 'Seleccionar imagen',
    changeImageTitle: 'Cambiar foto de perfil',
    AreYouSure: '¿Estás seguro que quieres eliminar tu cuenta?',
    profileDeleted: 'Tu cuenta ha sido eliminada, esperamos volver a verte pronto.',
    redirectHome: 'Serás redirigido a la página de inicio en {value} segundos',
    verifyYourAccount:
      'Por favor, verifica tu cuenta de usuario a través del enlace recibido en tu correo electrónico.',
    verifyBlockedAccountTitle: 'Debes verificar tu cuenta para acceder a Plataforma Tierra',
    verifyBlockedAccountText:
      'Te acabamos de enviar un correo electrónico con as intrucciones (recuerda revisar la carpeta de spam)',
    userNotVerified: 'Usuario no verificado',
    userNotVerifiedText:
      'Necesitamos confirmar tu correo electrónico para poder continuar. Cuando te registraste te enviamos un correo electrónico con un enlace para verificarlo. Revisa tu bandeja de entrada o tu carpeta de SPAM.',
    emailNotReceived: '¿No te ha llegado el correo?',
    tooltipDNI: "Para editar el DNI, debes rellenar el campo 'País'"
  },
  searchResults: {
    title: 'Resultados de búsqueda',
    description:
      'Aquí podrás consultar todo nuestro contenido ordenado por secciones y acotar los resultados mediante el buscador'
  },
  marketResults: {
    title: 'Mercados'
  },
  eventResults: {
    title: 'Mis eventos',
    description:
      'Desde aquí podrás consultar todos los eventos a los que te hayas inscrito, ver su detalle y acceder a ellos'
  },
  bookmark: {
    saved: 'Guardado',
    title: 'Elementos guardados',
    description: 'Aquí podrás consultar todo tu contenido guardado por secciones',
    path: '/guardados',
    empty: '¡No tienes ningún elemento guardado!'
  },
  startups: {
    title: 'Mi Startup',
    description: 'Aquí podrás ver toda la información sobre las convocatorias',
    path: '/mi-startup',
    button: 'Presentar mi candidatura',
    empty:
      'Todavía no has presentado tu candidatura a esta convocatoría, consulta la información aquí:'
  },
  map: {
    errorResult: 'No hubo resultados para la dirección ingresada.',
    errorUnknown: 'Error general del mapa.',
    errorGeneric: 'Error de la web. Contacte con Plataforma Tierra.'
  },
  community: {
    documentsUploadedBy: 'Documentos subidos por ',
    documents: 'Documentos ',
    aBlogOf: 'Un Blog de {name}',
    aisStatsNotResultDocuments: 'No hemos encontrado documentos relacionados con tu búsqueda',
    downloadDisclaimer: 'Descarga de responsabilidad:'
  },
  invitation: {
    invitationTitle: 'Registrate a este evento',
    eventButton: 'Ir al evento',
    incriptionTitle: 'Inscribirse al evento'
  },
  countdown: {
    days: 'Días',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos'
  },
  calendar: {
    presential: 'Presencial',
    web: 'Webinar'
  },
  megamenu: {
    title: 'Subsectores:'
  }
};
