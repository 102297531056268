import { setValueObjectByField } from '@/utils/helpers/object';

export default {
  SET_BY_FIELD: (state, { field, value }) => {
    setValueObjectByField(state, field, value);
  },

  setUser(state, user) {
    state.user = user;
  },
  setTempUser(state, user) {
    state.tempUser = user;
  },
  setLoadingAuth(state, val) {
    state.loadingAuth = val;
  },

  loggedIn(state, loggedIn) {
    state.loggedIn = loggedIn;
  },

  redirect(state, redirect) {
    state.redirect = redirect;
  },

  loading(state, loading) {
    state.loading = {
      status: loading.status,
      message: loading.message ?? ''
    };
  }
};
