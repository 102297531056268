export const editUser = {
  name: '',
  surname: '',
  email: '',
  dni: '',
  mobile_prefix: undefined,
  mobile_number: undefined,
  company_name: '',
  company_position: '',
  city_id: undefined,
  city: '',
  province_id: undefined,
  province: '',
  sector_id: '',
  sector: '',
  cliente_cajamar: false,
  country: '',
  country_id: undefined,
  picture: '',
  user_interests: {
    sectors: [],
    subsectors: []
  }
};
