import kpiService from '@/services/api/kpi/kpiServices';
export default {
  async getKpiList({ commit }, { pathParam, payload, httpClient }) {
    const { result } = await kpiService.getKpisList(pathParam, payload, httpClient);
    commit('SET_BY_FIELD', { field: 'kpiList', value: result });
  },

  async getKpisHistoric({ commit }, { pathParam, payload, httpClient, banner = false }) {
    const { result } = await kpiService.getKpisHistoric(pathParam, payload, httpClient);
    commit('SET_BY_FIELD', { field: banner ? 'kpiBanner' : 'kpiListHistoric', value: result });
  }
};
