import { configure } from 'vee-validate';
import { i18n } from '@/utils/i18n';
import { momentUpdateLocale } from '@/utils/helpers/date';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.use(i18n);
  configure({
    // this will be used to generate messages.
    generateMessage: field => {
      if (field === '{field}') return '';
      const fieldName = i18n.global.t(`field.${field.field}`);
      return field.rule ? i18n.global.t(`validation.${field.rule.name}`, fieldName) : '';
    }
  });

  // called right before setting a new locale
  // nuxtApp.hook('i18n:beforeLocaleSwitch', ({ oldLocale, newLocale, initialSetup }) => {
  //   console.log('onBeforeLanguageSwitch', oldLocale, newLocale, initialSetup);
  // });

  // called right after a new locale has been set
  nuxtApp.hook('i18n:localeSwitched', ({ _, newLocale }) => {
    momentUpdateLocale(newLocale, () => import(`@/locales/${newLocale}/moment`));
  });
  nuxtApp.provide('t', i18n.global.t);
  nuxtApp.provide('i18n', i18n.global);
});
