export default {
  setFilter: (state, newFilter) => {
    state.filter = newFilter;
  },
  setIndexName: (state, newIndexName) => {
    state.indexName = newIndexName;
  },
  setFilterAttribute: (state, newFilterAttribute) => {
    state.filterAttribute = newFilterAttribute;
  }
};
