import { createStore } from 'vuex';
import kpi from './kpi';
import auth from './auth';
import tools from './tools';
import strapi from './strapi';
import search from './search';
import profile from './profile';
import newsLetter from './newsLetter';

const modules = {
  kpi,
  auth,
  tools,
  strapi,
  search,
  profile,
  newsLetter
};

export default createStore({ modules });
