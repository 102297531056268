export default class kpiService {
  static async getKpisList(pathParam, params, httpClient) {
    const url = `${process.env.API_KPI_EXT}/${pathParam}`;
    const { data } = await httpClient.fetchGet(url, params);
    return data;
  }

  static async getKpisHistoric(pathParam, body, httpClient) {
    const url = `${process.env.API_KPI_EXT}/${pathParam}`;
    const { data } = await httpClient.fetchPost(url, body);
    return data;
  }
}
