import Vue3TouchEvents from 'vue3-touch-events';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('appear', {
    beforeMount(el, binding) {
      const condition = binding.arg ?? false;
      if (!!binding.value === !!condition) {
        el.click();
        el.focus();
      }
    },
    update(el, binding) {
      if (binding.arg != null) {
        if (!!binding.value === !!binding.arg) {
          el.click();
          el.focus();
        }
      }
    }
  });

  nuxtApp.vueApp.directive('click-outside', {
    bind(el, binding) {
      const handler = e => {
        if (binding.modifiers.bubble || (!el.contains(e.target) && el !== e.target)) {
          binding.value(e);
        }
      };
      el.outsideClick = handler;
      document.addEventListener('click', handler);
    },

    unbind(el) {
      document.removeEventListener('click', el.outsideClick);
      el.outsideClick = null;
    }
  });

  nuxtApp.vueApp.use(Vue3TouchEvents);
});
