import { default as accesibilidadzOXhusKcpPMeta } from "/home/galadriel/az-agent/_work/1/s/pages/accesibilidad.vue?macro=true";
import { default as _91slug_93TgxPPJYWWsMeta } from "/home/galadriel/az-agent/_work/1/s/pages/actualidad/[slug].vue?macro=true";
import { default as indexQhMmDeZEkgMeta } from "/home/galadriel/az-agent/_work/1/s/pages/actualidad/index.vue?macro=true";
import { default as actualizar_45usuario5TxBB38oV4Meta } from "/home/galadriel/az-agent/_work/1/s/pages/actualizar-usuario.vue?macro=true";
import { default as _91slug_93Sl8hQbdi0iMeta } from "/home/galadriel/az-agent/_work/1/s/pages/autor/[slug].vue?macro=true";
import { default as aviso_45legalHJMMRWfzY6Meta } from "/home/galadriel/az-agent/_work/1/s/pages/aviso-legal.vue?macro=true";
import { default as centros_45experimentalesqo55rpPk9wMeta } from "/home/galadriel/az-agent/_work/1/s/pages/centros-experimentales.vue?macro=true";
import { default as _91slug_93XVkNxxaSSNMeta } from "/home/galadriel/az-agent/_work/1/s/pages/comunidad/[blog]/[slug].vue?macro=true";
import { default as indexa9cUpeqZ3sMeta } from "/home/galadriel/az-agent/_work/1/s/pages/comunidad/[blog]/index.vue?macro=true";
import { default as indexY7MIfdBPtFMeta } from "/home/galadriel/az-agent/_work/1/s/pages/comunidad/autores/index.vue?macro=true";
import { default as indexTgj04FzqSnMeta } from "/home/galadriel/az-agent/_work/1/s/pages/comunidad/index.vue?macro=true";
import { default as condiciones_45de_45usoJjCLYYlS22Meta } from "/home/galadriel/az-agent/_work/1/s/pages/condiciones-de-uso.vue?macro=true";
import { default as cuaderno_45de_45explotacion_45cxthhgJtUOik3Meta } from "/home/galadriel/az-agent/_work/1/s/pages/cuaderno-de-explotacion-cxt.vue?macro=true";
import { default as _91slug_9347qNvmYAGKMeta } from "/home/galadriel/az-agent/_work/1/s/pages/encuesta/[slug].vue?macro=true";
import { default as establecer_45contrasena4KIdHqaFQ4Meta } from "/home/galadriel/az-agent/_work/1/s/pages/establecer-contrasena.vue?macro=true";
import { default as _91slug_93e75fLuwDGCMeta } from "/home/galadriel/az-agent/_work/1/s/pages/formacion/[slug].vue?macro=true";
import { default as indexPtv2dC5CM3Meta } from "/home/galadriel/az-agent/_work/1/s/pages/formacion/index.vue?macro=true";
import { default as gestion_45autenticacion2mq1408EIAMeta } from "/home/galadriel/az-agent/_work/1/s/pages/gestion-autenticacion.vue?macro=true";
import { default as guardadosMS0ltN3cYeMeta } from "/home/galadriel/az-agent/_work/1/s/pages/guardados.vue?macro=true";
import { default as _91slug_93K11ou7AABvMeta } from "/home/galadriel/az-agent/_work/1/s/pages/herramientas/[slug].vue?macro=true";
import { default as cxtierraBQxwQrGmWdMeta } from "/home/galadriel/az-agent/_work/1/s/pages/herramientas/cxtierra.vue?macro=true";
import { default as indexMWNSCHVXb1Meta } from "/home/galadriel/az-agent/_work/1/s/pages/index.vue?macro=true";
import { default as _91slug_93X3K46lcndHMeta } from "/home/galadriel/az-agent/_work/1/s/pages/innovacion/[slug].vue?macro=true";
import { default as indexcDssO5QNhtMeta } from "/home/galadriel/az-agent/_work/1/s/pages/innovacion/index.vue?macro=true";
import { default as _91slug_93nZWahVIK8jMeta } from "/home/galadriel/az-agent/_work/1/s/pages/invita/[slug].vue?macro=true";
import { default as _91slug_937K5iTRWNkSMeta } from "/home/galadriel/az-agent/_work/1/s/pages/mercados/[slug].vue?macro=true";
import { default as _91slug_93qo6FLHZGrhMeta } from "/home/galadriel/az-agent/_work/1/s/pages/mercados/actualidad/[slug].vue?macro=true";
import { default as indexA6I8k9OQ2PMeta } from "/home/galadriel/az-agent/_work/1/s/pages/mercados/index.vue?macro=true";
import { default as _91subsector_93BUQEjJHBUPMeta } from "/home/galadriel/az-agent/_work/1/s/pages/mercados/sector/[sector]/subsector/[subsector].vue?macro=true";
import { default as mi_45startupWJxD521657Meta } from "/home/galadriel/az-agent/_work/1/s/pages/mi-startup.vue?macro=true";
import { default as mis_45eventosXpbcEDUn6eMeta } from "/home/galadriel/az-agent/_work/1/s/pages/mis-eventos.vue?macro=true";
import { default as perfilyZEEGD3jKzMeta } from "/home/galadriel/az-agent/_work/1/s/pages/perfil.vue?macro=true";
import { default as politica_45de_45cookies_45cxtierrau4yckhvTykMeta } from "/home/galadriel/az-agent/_work/1/s/pages/politica-de-cookies-cxtierra.vue?macro=true";
import { default as politica_45de_45cookiesED8UZMJ6pGMeta } from "/home/galadriel/az-agent/_work/1/s/pages/politica-de-cookies.vue?macro=true";
import { default as privacidadlAC8U3UgpPMeta } from "/home/galadriel/az-agent/_work/1/s/pages/privacidad.vue?macro=true";
import { default as _91slug_93M2Ogz2zOz7Meta } from "/home/galadriel/az-agent/_work/1/s/pages/proyecto/[slug].vue?macro=true";
import { default as publicacionesEdDyWPg1ENMeta } from "/home/galadriel/az-agent/_work/1/s/pages/publicaciones.vue?macro=true";
import { default as quienes_45somosgMQjYNy2A8Meta } from "/home/galadriel/az-agent/_work/1/s/pages/quienes-somos.vue?macro=true";
import { default as registrofCBhb9pGh7Meta } from "/home/galadriel/az-agent/_work/1/s/pages/registro.vue?macro=true";
import { default as resultados_45busquedaRGRu31mr3rMeta } from "/home/galadriel/az-agent/_work/1/s/pages/resultados-busqueda.vue?macro=true";
import { default as indexMszfcNdGsmMeta } from "/home/galadriel/az-agent/_work/1/s/pages/startups/[convocatoria]/index.vue?macro=true";
import { default as inscripcionnR6NgOrg51Meta } from "/home/galadriel/az-agent/_work/1/s/pages/startups/[convocatoria]/inscripcion.vue?macro=true";
import { default as index2AZ8Rv7KC9Meta } from "/home/galadriel/az-agent/_work/1/s/pages/startups/index.vue?macro=true";
import { default as verificacion_45usuarioFzfI6eq5HlMeta } from "/home/galadriel/az-agent/_work/1/s/pages/verificacion-usuario.vue?macro=true";
import { default as _91slug_93MRuCKfjrLKMeta } from "/home/galadriel/az-agent/_work/1/s/pages/visita-nuestros-centros-experimentales/[slug].vue?macro=true";
import { default as component_45stubwovVQwiaPJMeta } from "/home/galadriel/az-agent/_work/1/s/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubwovVQwiaPJ } from "/home/galadriel/az-agent/_work/1/s/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "accesibilidad",
    path: "/accesibilidad",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/accesibilidad.vue").then(m => m.default || m)
  },
  {
    name: "actualidad-slug",
    path: "/actualidad/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/actualidad/[slug].vue").then(m => m.default || m)
  },
  {
    name: "actualidad",
    path: "/actualidad",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/actualidad/index.vue").then(m => m.default || m)
  },
  {
    name: "actualizar-usuario",
    path: "/actualizar-usuario",
    meta: actualizar_45usuario5TxBB38oV4Meta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/actualizar-usuario.vue").then(m => m.default || m)
  },
  {
    name: "autor-slug",
    path: "/autor/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/autor/[slug].vue").then(m => m.default || m)
  },
  {
    name: "aviso-legal",
    path: "/aviso-legal",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/aviso-legal.vue").then(m => m.default || m)
  },
  {
    name: "centros-experimentales",
    path: "/centros-experimentales",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/centros-experimentales.vue").then(m => m.default || m)
  },
  {
    name: "comunidad-blog-slug",
    path: "/comunidad/:blog()/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/comunidad/[blog]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "comunidad-blog",
    path: "/comunidad/:blog()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/comunidad/[blog]/index.vue").then(m => m.default || m)
  },
  {
    name: "comunidad-autores",
    path: "/comunidad/autores",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/comunidad/autores/index.vue").then(m => m.default || m)
  },
  {
    name: "comunidad",
    path: "/comunidad",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/comunidad/index.vue").then(m => m.default || m)
  },
  {
    name: "condiciones-de-uso",
    path: "/condiciones-de-uso",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/condiciones-de-uso.vue").then(m => m.default || m)
  },
  {
    name: "cuaderno-de-explotacion-cxt",
    path: "/cuaderno-de-explotacion-cxt",
    meta: cuaderno_45de_45explotacion_45cxthhgJtUOik3Meta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/cuaderno-de-explotacion-cxt.vue").then(m => m.default || m)
  },
  {
    name: "encuesta-slug",
    path: "/encuesta/:slug()",
    meta: _91slug_9347qNvmYAGKMeta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/encuesta/[slug].vue").then(m => m.default || m)
  },
  {
    name: "establecer-contrasena",
    path: "/establecer-contrasena",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/establecer-contrasena.vue").then(m => m.default || m)
  },
  {
    name: "formacion-slug",
    path: "/formacion/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/formacion/[slug].vue").then(m => m.default || m)
  },
  {
    name: "formacion",
    path: "/formacion",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/formacion/index.vue").then(m => m.default || m)
  },
  {
    name: "gestion-autenticacion",
    path: "/gestion-autenticacion",
    meta: gestion_45autenticacion2mq1408EIAMeta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/gestion-autenticacion.vue").then(m => m.default || m)
  },
  {
    name: "guardados",
    path: "/guardados",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/guardados.vue").then(m => m.default || m)
  },
  {
    name: "herramientas-slug",
    path: "/herramientas/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/herramientas/[slug].vue").then(m => m.default || m)
  },
  {
    name: "herramientas-cxtierra",
    path: "/herramientas/cxtierra",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/herramientas/cxtierra.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "innovacion-slug",
    path: "/innovacion/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/innovacion/[slug].vue").then(m => m.default || m)
  },
  {
    name: "innovacion",
    path: "/innovacion",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/innovacion/index.vue").then(m => m.default || m)
  },
  {
    name: "invita-slug",
    path: "/invita/:slug()",
    meta: _91slug_93nZWahVIK8jMeta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/invita/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mercados-slug",
    path: "/mercados/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/mercados/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mercados-actualidad-slug",
    path: "/mercados/actualidad/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/mercados/actualidad/[slug].vue").then(m => m.default || m)
  },
  {
    name: "mercados",
    path: "/mercados",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/mercados/index.vue").then(m => m.default || m)
  },
  {
    name: "mercados-sector-sector-subsector-subsector",
    path: "/mercados/sector/:sector()/subsector/:subsector()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/mercados/sector/[sector]/subsector/[subsector].vue").then(m => m.default || m)
  },
  {
    name: "mi-startup",
    path: "/mi-startup",
    meta: mi_45startupWJxD521657Meta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/mi-startup.vue").then(m => m.default || m)
  },
  {
    name: "mis-eventos",
    path: "/mis-eventos",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/mis-eventos.vue").then(m => m.default || m)
  },
  {
    name: "perfil",
    path: "/perfil",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/perfil.vue").then(m => m.default || m)
  },
  {
    name: "politica-de-cookies-cxtierra",
    path: "/politica-de-cookies-cxtierra",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/politica-de-cookies-cxtierra.vue").then(m => m.default || m)
  },
  {
    name: "politica-de-cookies",
    path: "/politica-de-cookies",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/politica-de-cookies.vue").then(m => m.default || m)
  },
  {
    name: "privacidad",
    path: "/privacidad",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/privacidad.vue").then(m => m.default || m)
  },
  {
    name: "proyecto-slug",
    path: "/proyecto/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/proyecto/[slug].vue").then(m => m.default || m)
  },
  {
    name: "publicaciones",
    path: "/publicaciones",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/publicaciones.vue").then(m => m.default || m)
  },
  {
    name: "quienes-somos",
    path: "/quienes-somos",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/quienes-somos.vue").then(m => m.default || m)
  },
  {
    name: "registro",
    path: "/registro",
    meta: registrofCBhb9pGh7Meta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/registro.vue").then(m => m.default || m)
  },
  {
    name: "resultados-busqueda",
    path: "/resultados-busqueda",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/resultados-busqueda.vue").then(m => m.default || m)
  },
  {
    name: "startups-convocatoria",
    path: "/startups/:convocatoria()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/startups/[convocatoria]/index.vue").then(m => m.default || m)
  },
  {
    name: "startups-convocatoria-inscripcion",
    path: "/startups/:convocatoria()/inscripcion",
    meta: inscripcionnR6NgOrg51Meta || {},
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/startups/[convocatoria]/inscripcion.vue").then(m => m.default || m)
  },
  {
    name: "startups",
    path: "/startups",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/startups/index.vue").then(m => m.default || m)
  },
  {
    name: "verificacion-usuario",
    path: "/verificacion-usuario",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/verificacion-usuario.vue").then(m => m.default || m)
  },
  {
    name: "visita-nuestros-centros-experimentales-slug",
    path: "/visita-nuestros-centros-experimentales/:slug()",
    component: () => import("/home/galadriel/az-agent/_work/1/s/pages/visita-nuestros-centros-experimentales/[slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubwovVQwiaPJMeta?.name,
    path: "/formacion/las-ng-ts-como-herramienta-clave-para-un-agricultura-sostenible-en-la-union-europea",
    component: component_45stubwovVQwiaPJ
  }
]