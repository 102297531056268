import veeValidate from './vee-validate';

export default {
  ...veeValidate,
  previewMode: {
    text: 'Draft Preview',
    button: 'Click here to refresh'
  },
  message: {
    generic: {},
    error: {},
    success: {}
  },
  auth: {
    username: 'User',
    password: 'Password',
    welcomeText: 'Welcome,',
    buttons: {
      login: 'Login',
      logout: 'Logout',
      preferences: 'Get createUser preferences',
      unsubscribe: 'Unsubscribe',
      accept: 'Acept',
      cancel: 'Cancel'
    }
  },
  forms: {
    errors: {
      signUp: {
        401: 'The password cannot contain part of the email',
        403: 'Check your inbox because the email is already registered',
        409: 'The email address you entered already exists'
      },
      login: {
        400: 'Email or password is incorrect'
      },
      throwError: 'An error has occured'
    }
  },
  ui: {
    button: {
      processing: 'Processing',
      save: 'Save',
      share: 'Share'
    },
    label: {},
    placeholder: {}
  },
  pages: {
    events: {
      contents_h2: '¿Qué vas a aprender en este evento?'
    }
  },
  register: {
    detail: {
      relatedContent: 'Related content',
      interest: 'It may interest you'
    },
    legends: {
      areCustomer: 'Are you a client of the Cajamar Cooperative Group?',
      chooseActivity: 'Choose an a sector activity',
      chooseSubsector: 'Choose the agro subsectors of your interest',
      chooseSubsector_small: '(Max 8)',
      legalWarning: 'Legal warning - conditions',
      personal: 'Personal information'
    },
    activitySector: 'Activity sector',
    dataProtection:
      'For more details on the processing of your data, see our Data Protection Policy. By clicking Submit, you agree that Plataforma Tierra stores and processes the personal information provided above to provide you with the requested service.',
    iRead: 'I read the ',
    lastDatas: 'Complete the following information to complete the registration in the course.',
    legalWarning: 'Legal warning',
    weeklyNewsletter: 'I want to receive the weekly newsletter',
    messageConfirmationEmail: 'We will send you confirmation email',
    sectors: {
      fruit: 'Fruit',
      livestock: 'Livestock'
    }
  }
};
