import {
  LogLevel,
  BrowserCacheLocation,
  PublicClientApplication,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import {
  FORMATION_SLUG,
  AUTH_STATUS_NAME,
  SUCCESS_PASSWORD_RESET_URL,
  MSAL_REDIRECT_URI
} from '~/utils/enums';
import store from '@/store';

export default defineNuxtPlugin(async () => {
  const route = useRoute();
  const router = useRouter(); // Provisional
  const ua = process.client ? window.navigator.userAgent : '';
  const msie = ua.indexOf('MSIE ');
  const msie11 = ua.indexOf('Trident/');
  const msedge = ua.indexOf('Edge/');
  const firefox = ua.indexOf('Firefox');
  const isIE = msie > 0 || msie11 > 0;
  const isEdge = msedge > 0;
  const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

  const scopes = ['openid', 'offline_access', process.env.APP_MS_EXPOSE_API];

  const msalConfig = {
    auth: {
      clientId: process.env.APP_MS_CLIENT_ID,
      authority: process.env.APP_MS_AUTHORITY_SIGNIN,
      knownAuthorities: process.env.APP_MS_KNOWN_AUTHORITIES.split(','),
      redirectUri: MSAL_REDIRECT_URI,
      navigateToLoginRequestUrl: true
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
          }
        },
        logLevel: process.env.APP_IS_PROD_ENV === 'enabled' ? LogLevel.Error : LogLevel.Verbose
      }
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE || isEdge || isFirefox // Set this to "true" if you are having issues on IE11 or Edge or Firefox
    }
  };

  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();

  const handleRedirectResponse = resp => {
    const isInIframe = window.parent !== window;
    if (!isInIframe) {
      if (resp !== null) {
        const { idTokenClaims } = resp;
        const policy = idTokenClaims.acr.toUpperCase();
        if (policy === 'B2C_1A_SIGNIN') {
          handleResponse(resp);
        } else {
          handlePolicyChange(policy);
        }
      } else {
        ssoSilent();
      }
    }
  };

  // Register Callbacks for Redirect flow
  msalInstance
    .handleRedirectPromise()
    .then(handleRedirectResponse)
    .catch(error => {
      const { errorCode, errorMessage } = error;
      if (errorCode === 'access_denied') {
        if (errorMessage.includes('AADB2C90118')) {
          passwordReset();
        }
      }
      setLoadingAuth(false);
    });

  const setAccount = async (account, state) => {
    // After a successful login set the active account to be the user that just logged in
    msalInstance.setActiveAccount(account);
    // Dispatch Global Event in action Login
    window.dispatchEvent(new CustomEvent('app-on-login'));
    // Wait for user profile to load
    if (!route.query.token) {
      await store.dispatch('auth/getUserProfile');
      store.commit('auth/loggedIn', true);
      verifyEnrolledState(AUTH_STATUS_NAME.login);
      if (!store.state.profile.favoriteLoaded) await store.dispatch('profile/getFavorites');
    }
    const redirect = decodeURIComponent(state); // Provisional
    if (redirect && redirect !== route.path) router.push(redirect); // Provisional
    setLoadingAuth(false);
  };

  const verifyEnrolledState = hash => {
    const {
      name,
      params: { slug }
    } = route;
    if (FORMATION_SLUG === name) {
      if (hash === AUTH_STATUS_NAME.login) {
        store.dispatch('profile/checkUserIsEnrolledInEvent', { slug });
      }
    }
  };

  const selectAccount = () => {
    const currentAccounts = msalInstance.getAllAccounts();

    if (currentAccounts.length < 1) return;

    if (currentAccounts.length > 1) {
      const accounts = currentAccounts.filter(
        account =>
          account.homeAccountId.toUpperCase().includes('B2C_1A_SIGNIN') &&
          account.idTokenClaims.iss
            .toUpperCase()
            .includes(process.env.APP_MS_KNOWN_AUTHORITIES.split(',')[0]) &&
          account.idTokenClaims.aud === msalConfig.auth.clientId
      );

      if (accounts.length > 1) {
        if (accounts.every(account => account.localAccountId === accounts[0].localAccountId)) {
          setAccount(accounts[0]);
        } else {
          // Multiple users detected. Logout all to be safe.
          signOut();
        }
      } else if (accounts.length === 1) {
        setAccount(accounts[0]);
      }
    } else if (currentAccounts.length === 1) {
      setAccount(currentAccounts[0]);
    }
  };

  const handleResponse = async response => {
    if (response !== null) {
      const { state, account } = response;
      await setAccount(account, state); // Provisional
      /* if (state) {
        store.commit('auth/redirect', decodeURIComponent(state));
      } */
    } else {
      selectAccount();
    }
  };

  const handlePolicyChange = policy => {
    if (policy === 'B2C_1A_PROFILEEDIT') {
      console.log('El perfil ha sido actualizado con éxito. Por favor inicie sesión de nuevo.');
    } else if (policy === 'B2C_1A_PASSWORDRESET') {
      signOut(SUCCESS_PASSWORD_RESET_URL);
    }
  };

  const loginRedirect = (state, authority, params) => {
    const request = {
      scopes
    };
    const authParams = {
      loginHint: params?.email || ''
    };
    if (state) request.state = encodeURIComponent(state);
    if (authority) request.authority = authority;
    msalInstance.loginRedirect({ ...request, ...authParams });
  };

  const redirectUriCustom = (action, returnUri, loginParams) => {
    loadingPage(true, action);
    loginRedirect(
      returnUri,
      action === 'passwordReset' ? process.env.APP_MS_AUTHORITY_PASSWORDRESET : null,
      loginParams
    );
  };

  const signIn = (returnUri, loginParams) => {
    redirectUriCustom('signIn', returnUri, loginParams);
  };

  const ssoFromExternal = (state, loginParams) => {
    if (getActiveAccount()) {
      if (state) store.commit('auth/redirect', decodeURIComponent(state));
    } else {
      loginRedirect(state, undefined, loginParams);
    }
  };

  const passwordReset = (returnUri, loginParams) => {
    redirectUriCustom('passwordReset', returnUri, loginParams);
  };

  const signOut = route => {
    loadingPage(true, 'signOut');
    const request = { account: msalInstance.getActiveAccount() };
    if (route) request.postLogoutRedirectUri = route;
    msalInstance.logoutRedirect(request);
  };

  // Acquires and access token and then passes it to the API call
  const acquireToken = () => {
    const account = getActiveAccount();
    if (!account) return null;

    // Providing an account in the token request is not required if there is an active account set
    const tokenSilentRequest = {
      account,
      scopes,
      forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
    };

    return msalInstance
      .acquireTokenSilent(tokenSilentRequest)
      .then(({ idToken }) => idToken)
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          // Fallback to interaction when silent call fails
          // return msalInstance.acquireTokenRedirect(tokenSilentRequest);
        } else {
          console.error(error);
        }
        return '';
      });
  };

  const ssoSilent = () => {
    const account = getActiveAccount();
    if (!account) {
      setLoadingAuth(false);
      return null;
    }

    // Providing an account in the token request is not required if there is an active account set
    msalInstance
      .ssoSilent({ account })
      .then(async response => {
        await setAccount(response.account, response.state); // Provisional
      })
      .catch(error => {
        if (error instanceof InteractionRequiredAuthError) {
          // Fallback to interaction when silent call fails
          // return msalInstance.acquireTokenRedirect(ssoSilentRequest);
          // Logout all to be safe.
          signOut();
        }
      });
  };

  const getIsAuthenticated = () => {
    const accounts = msalInstance.getAllAccounts();
    return accounts && accounts.length > 0;
  };

  const getActiveAccount = () => msalInstance.getActiveAccount();

  const loadingPage = (status, action) => {
    store.commit('auth/loading', { status, message: action ?? '' });
  };

  const setLoadingAuth = state => {
    store.commit('auth/setLoadingAuth', state);
  };

  return {
    provide: {
      msal: {
        signIn,
        signOut,
        ssoSilent,
        loadingPage,
        acquireToken,
        passwordReset,
        ssoFromExternal,
        getActiveAccount,
        getIsAuthenticated
      }
    }
  };
});
