import {
  AisHits,
  AisStats,
  AisSortBy,
  AisSearchBox,
  AisHighlight,
  AisPagination,
  AisHitsPerPage,
  AisInfiniteHits,
  AisAutocomplete,
  AisInstantSearch,
  AisRefinementList,
  AisClearRefinements,
  AisConfigure,
  AisPanel
} from 'vue-instantsearch/vue3/es';

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.component(AisHits.name, AisHits);
  nuxtApp.vueApp.component(AisStats.name, AisStats);
  nuxtApp.vueApp.component(AisSortBy.name, AisSortBy);
  nuxtApp.vueApp.component(AisSearchBox.name, AisSearchBox);
  nuxtApp.vueApp.component(AisHighlight.name, AisHighlight);
  nuxtApp.vueApp.component(AisPagination.name, AisPagination);
  nuxtApp.vueApp.component(AisHitsPerPage.name, AisHitsPerPage);
  nuxtApp.vueApp.component(AisInfiniteHits.name, AisInfiniteHits);
  nuxtApp.vueApp.component(AisAutocomplete.name, AisAutocomplete);
  nuxtApp.vueApp.component(AisInstantSearch.name, AisInstantSearch);
  nuxtApp.vueApp.component(AisRefinementList.name, AisRefinementList);
  nuxtApp.vueApp.component(AisClearRefinements.name, AisClearRefinements);
  nuxtApp.vueApp.component(AisConfigure.name, AisConfigure);
  nuxtApp.vueApp.component(AisPanel.name, AisPanel);
});
