import { defineRule } from 'vee-validate';
import {
  required,
  max,
  regex,
  min,
  email,
  numeric,
  alpha,
  // eslint-disable-next-line camelcase
  alpha_spaces
} from '@vee-validate/rules';

export default defineNuxtPlugin(() => {
  defineRule('required', required);
  defineRule('requiredGeneric', required);
  defineRule('alpha', alpha);
  defineRule('alpha_spaces', alpha_spaces);
  defineRule('numeric', numeric);
  defineRule('requiredFamilyName', required);
  defineRule('regex', regex);

  defineRule('password', (value, { target }) => value === target);

  defineRule('email', email);
  defineRule('min', min);
  defineRule('minPhone', min);
  defineRule('maxPhone', max);

  defineRule('at_least_1_uppercase', value => {
    const strongRegex = /(?=.*?[A-Z])/;
    return !!strongRegex.test(value);
  });

  defineRule('requiredGenericRadio', value => !(value === null || value === undefined));

  defineRule('at_least_1_lowercase', value => {
    const strongRegex = /(?=.*?[a-z])/;
    return !!strongRegex.test(value);
  });

  defineRule('at_least_1_digit', value => {
    const strongRegex = /(?=.*?[0-9])/;
    return !!strongRegex.test(value);
  });

  defineRule('at_least_1_special', value => {
    const strongRegex = /(?=.*?[#?!@$%^&*"?¿@_[\]€(){}'<>-])/;
    return !!strongRegex.test(value);
  });

  defineRule('regexAzure', value => {
    const strongRegex =
      /^(?!.*[^A-Za-z0-9@#$%^&*-_+=[{}|:',?/`~();.]$)(?![a-z]*$)(?![A-Z]*$)(?![0-9]*$)(?![@#$%^&*-_+=[{}|:',?/`~();.]*$).{8,64}$/;
    return !!strongRegex.test(value);
  });

  defineRule('emailPass', (value, email) => {
    const partsOfThreeLetters = email[0]
      .match(/.{5}/g)
      .concat(email[0].substr(1).match(/.{5}/g), email[0].substr(2).match(/.{5}/g));
    return !new RegExp(partsOfThreeLetters.join('|'), 'i').test(value);
  });

  defineRule('is_password_strength', value => {
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[¡!"#$%&'()*+,-.:;<=>?@{}/^_~`|\\[\\]])(?=.{8,})/;
    return !!strongRegex.test(value);
  });

  defineRule('dni_nie', dni => {
    let numero, letraDni, letra;
    const expresionRegularDni = /^[XYZ]?\d{5,8}[A-Z]$/;

    const dniUpperCase = dni?.toUpperCase();

    if (expresionRegularDni.test(dniUpperCase) === true) {
      numero = dniUpperCase.substr(0, dniUpperCase.length - 1);
      numero = numero.replace('X', 0);
      numero = numero.replace('Y', 1);
      numero = numero.replace('Z', 2);
      letraDni = dniUpperCase.substr(dniUpperCase.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra !== letraDni) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });

  defineRule('passport', passport => {
    const expresionRegularPassport = /^[A-Z]{3}\d{6}$/;

    // Validar si el formato del pasaporte es correcto
    if (expresionRegularPassport.test(passport?.toUpperCase()) === true) {
      return true;
    } else {
      return false;
    }
  });
});
