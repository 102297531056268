import profileService from '@/services/api/profile/profileService';

export default {
  async getUserProfile({ commit }, idToken) {
    const { $axios } = useNuxtApp();
    $axios.interceptors.request.use(config => {
      if (idToken) config.headers.Authorization = idToken;
      return config;
    });
    const { userProfile } = await profileService.getProfileData('userProfile', idToken);
    if (idToken) commit('setTempUser', userProfile);
    else commit('setUser', userProfile);
  }
};
