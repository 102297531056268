export default class toolsService {
  static async getDailyForecast() {
    const { $axios } = useNuxtApp();
    const url = `/meteo/dailforecastfarms`;
    const { data } = await $axios.fetchGet(url);
    return data;
  }

  static async postZip(zip) {
    const { $axios } = useNuxtApp();
    const url = `/cultivos/postalcode/${zip}`;
    const { data } = await $axios.fetchGet(url);
    return data;
  }

  static async postExploitation(nombre) {
    const { $axios } = useNuxtApp();
    const url = `/cultivos/exploitations`;
    const { data } = await $axios.fetchPost(url, { nombre });
    return data;
  }

  static async postFarm(idExploitation, payload) {
    const { $axios } = useNuxtApp();
    const url = `/cultivos/exploitations/${idExploitation}/farms`;
    const { data } = await $axios.fetchPost(url, payload);
    return data;
  }

  static async identityHyvorData() {
    const { $axios } = useNuxtApp();
    const url = '/identity/hyvorData';
    const data = await $axios.fetchPost(url, {});
    return data;
  }
}
