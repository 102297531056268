/* eslint-disable max-lines */
export const HOME_PAGE = '/';
export const PROFILE_PAGE = '/perfil';
export const INNOVATION_PAGE = '/innovacion';
export const COURSE_BASE_URL = '/formacion';
export const ABOUT_BASE_URL = '/quienes-somos';
export const MARKET_BASE_URL = '/mercados';
export const AUTHOR_BASE_URL = '/autor';
export const TOOLS_BASE_URL = process.env.APP_TOOLS_BASE_URL;
export const OUR_TOOLS_BASE_URL = '/herramientas';
export const NEWSROOMS_BASE_URL = '/actualidad';
export const PROJECTS_BASE_URL = '/proyecto';
export const COMMUNITY_BASE_URL = '/comunidad';
export const PUBLICATIONS_BASE_URL = '/publicaciones';
export const REGISTER_BASE_URL = '/registro';
export const CXTIERRA_BASE_URL = `${OUR_TOOLS_BASE_URL}/cxtierra`;
export const SURVEYS_BASE_URL = '/encuesta';
export const INVITATIONS_BASE_URL = '/invita';
export const STARTUPS_BASE_UR = '/startups';
export const MY_STARTUPS_BASE_UR = '/mi-startup';
export const RSS_BASE_URL = '/feed';
export const SUCCESS_PASSWORD_RESET_URL = '/establecer-contrasena';
export const MSAL_REDIRECT_URI = '/gestion-autenticacion';
export const VISIT_OUR_EXPERIMENTAL_CENTERS_BASE_URL = '/centros-experimentales';
export const FORM_VISIT_OUR_EXPERIMENTAL_CENTERS_BASE_URL =
  '/visita-nuestros-centros-experimentales';
export const FORMATION_SLUG = 'formacion-slug';
export const PRESENT_SLUG = 'actualidad-slug';
export const MY_EVENTS_BASE_URL = '/mis-eventos';
export const SEARCH_RESULTS_URL = '/resultados-busqueda';
export const FAVOURITES_URL = '/guardados';
export const INSCRIPTION_STARTUPS_URL = '/inscripcion';
export const EMAIL_FORMATION = 'aprende@plataformatierra.es';
export const CXTIERRA_URL = process.env.APP_CXTIERRA_URL;
export const ERROR_CODE = [201, 400, 401, 403, 404, 409, 1005, 1006, 8001, 8002, 8003];
export const CAJAMAR_URL = 'https://www.grupocooperativocajamar.es/es/agroalimentario/';
export const SUCCESS_CODE = [200, 201];

export const AUTH_STATUS_NAME = {
  login: 'login',
  logout: 'logout'
};

export const OG_DESCRIPTION_META_TAG_DEFAULT =
  'Una iniciativa del Grupo Cajamar para la digitalización y la sostenibilidad del sector agroalimentario';

export const ROUTE_NAME_TO_RELOAD = [
  'mercados',
  'mercados-sector-slug',
  'herramientas',
  'perfil',
  'guardados',
  'mis-eventos'
];

export const HEADER_ITEMS = [
  {
    label: 'Mercados',
    url: '/mercados'
  },
  {
    label: 'Innovación',
    url: '/innovacion'
  },
  {
    label: 'Actualidad',
    url: '/actualidad'
  },
  {
    label: 'Herramientas',
    url: '/herramientas'
  },
  {
    label: 'Formación',
    url: '/formacion'
  },
  {
    label: 'Comunidad',
    url: '/comunidad'
  },
  {
    label: 'Publicaciones',
    url: '/publicaciones'
  }
];

export const PASSWORD_STRONG = {
  color: '#4caf50',
  claim: 'forms.claims.strong',
  value: 100
};

export const PASSWORD_WEAK = {
  color: '#F66748',
  claim: 'forms.claims.weak',
  value: 66
};

export const PASSWORD_NOT_STRONG = {
  color: '#D01E1E',
  claim: 'forms.claims.notStrong',
  value: 33
};

export const LOGO_CAJAMAR = {
  url: process.env.APP_FORMACION_LOGO_CAJAMAR,
  alternativeText: 'Cajamar'
};

export const LOGO_TIERRA = {
  src: '/logo-tierra.svg',
  alternativeText: 'Plataforma Tierra Logo'
};

export const NOT_IMAGE = {
  src: '/no-image.webp',
  alternativeText: 'Sin imagen'
};

export const BIBLIO_TIERRA_FACET = 'component_type_facets';

export const BIBLIO_TIERRA_FACET_BOOKS_REPORTS_OBSERVATORY = [
  'content.books',
  'content.observatory',
  'content.market-report'
];

export const BIBLIO_TIERRA_FACET_REPORTS = 'content.market-report';

export const EVENTS_FACETS = [
  {
    name: 'Clasificación',
    attribute: 'event_classification_facets',
    transform: true,
    collapse: true
  },
  { name: 'Temática', attribute: 'tags_facets', collapse: true },
  { name: 'Sectores', attribute: 'sectors_facets', collapse: true },
  { name: 'Subsectores', attribute: 'subsectors_facets', collapse: true },
  { name: 'Nivel', attribute: 'event_level_facets', transform: true, collapse: true },
  {
    name: 'Material adicional',
    attribute: 'event_will_have_material_facets',
    transform: true,
    collapse: true
  }
];
export const PROJECTS_FACETS = [
  { name: 'Estado', attribute: 'state_facets' },
  { name: 'Subsectores', attribute: 'subsectors_facets' },
  { name: 'Etiquetas', attribute: 'tags_facets' }
];

export const SEARCH_FACETS = [
  { name: 'Etiquetas', attribute: 'tags_facets' },
  { name: 'Sectores', attribute: 'sectors_facets' },
  { name: 'Subsectores', attribute: 'subsectors_facets' },
  { name: 'Categorías', attribute: 'category_facets' },
  { name: 'Años', attribute: 'published_year_facets' }
];
export const INFO_FACETS = [{ name: 'Indicadores', attribute: 'section_facets' }];
export const DOCUMENTS_FACETS = [
  { name: 'Categorías', attribute: 'category_facets' },
  { name: 'Año de publicación', attribute: 'published_year_facets' }
];

export const POST_FACETS = [
  { name: 'Autores', attribute: 'authors_facets' },
  { name: 'Título de Blog', attribute: 'blog_facets' },
  { name: 'Etiquetas', attribute: 'tags_facets' }
];

export const POST_FACETS_BY_BLOG = [
  { name: 'Categorías', attribute: 'category_facets' },
  { name: 'Etiquetas', attribute: 'tags_facets' },
  { name: 'Autores', attribute: 'authors_facets' }
];
export const MARKET_COMMENTS_FACETS = [{ name: 'Subsectores', attribute: 'subsectors_facets' }];

export const POST_FACET = 'posts_facets';

export const PUBLICATIONS_FACETS = [
  { name: 'Filtra por:', attribute: BIBLIO_TIERRA_FACET, transform: true }
];

export const EVENTS_FACETS_FOR_MENU = 'event_format_facets';

export const MARKETS_FACETS = [
  { name: 'Subsectores', attribute: 'subsectors_facets' },
  { name: 'Productos', attribute: 'product_facets' },
  { name: 'Secciones', attribute: 'section_facets' }
];

export const MARKETS_LANDING_TABLE = {
  aceite_de_oliva: [{ label: 'AOVE' }, { label: 'AOV' }, { label: 'AOL' }],
  herbaceos: [
    { label: 'Forrajeras' },
    { label: 'Arroz' },
    { label: 'Trigo' },
    { label: 'Cebada' },
    { label: 'Maíz' },
    { label: 'Soja' }
  ],
  hortalizas: [{ label: 'Aire libre' }, { label: 'Invernadero' }],
  vitivinicola: [{ label: 'Vino blanco sin DOP/IGP' }, { label: 'Vino tinto sin DOP/IGP' }]
};

export const ARTICLES_FACET_INNO_PUB = 'category_facets';

export const ACTIVITY_ITEMS = [
  { sector: 'Agricultor / Ganadero', sector_id: 'agricultor-ganadero' },
  {
    sector: 'Industrial, industria auxiliar y servicios',
    sector_id: 'industrial-auxiliar-servicios'
  },
  { sector: 'Conocimiento', sector_id: 'conocimiento' },
  { sector: 'Público, institucional y asociativo', sector_id: 'publico-institucional' },
  { sector: 'Financiero', sector_id: 'financiero' },
  { sector: 'Consumidor', sector_id: 'consumidor' },
  { sector: 'Otros', sector_id: 'otros' }
];

export const VISIT_LANGUAGE = [
  { label: 'experimentalCenters.form.language.spanish', value: 'Español' },
  { label: 'experimentalCenters.form.language.english', value: 'Inglés' }
];

export const VISIT_PROFILE = [
  { label: 'experimentalCenters.form.visitProfile.informative', value: 'Divulgativo' },
  { label: 'experimentalCenters.form.visitProfile.scientific', value: 'Científico' },
  { label: 'experimentalCenters.form.visitProfile.technical', value: 'Técnico' }
];

export const VISIT_THEMATIC = [
  { label: 'experimentalCenters.form.thematic.general', value: 'General' },
  { label: 'experimentalCenters.form.thematic.bioeconomy', value: 'Bioeconomía' },
  { label: 'experimentalCenters.form.thematic.agrosustainability', value: 'Agrosostenibilidad' },
  { label: 'experimentalCenters.form.thematic.nutritionHealth', value: 'Alimentación y Salud' },
  { label: 'experimentalCenters.form.thematic.greenhouse', value: 'Tecnología de invernaderos' }
];

export const VISIT_REASON = [
  { label: 'experimentalCenters.form.reason.advice', value: 'Asesoramiento' },
  { label: 'experimentalCenters.form.reason.informative', value: 'Informativo' },
  { label: 'experimentalCenters.form.reason.followUp', value: 'Seguimiento' },
  { label: 'experimentalCenters.form.reason.meeting', value: 'Reunión' }
];

export const KIND_PERSON = [
  { label: 'experimentalCenters.form.kindPerson.particular', value: 'Particular' },
  { label: 'experimentalCenters.form.kindPerson.enterprite', value: 'Empresa' },
  { label: 'experimentalCenters.form.kindPerson.public', value: 'Organismo Público' },
  { label: 'experimentalCenters.form.kindPerson.school', value: 'Colegio/Instituto' },
  { label: 'experimentalCenters.form.kindPerson.university', value: 'Universidad' },
  { label: 'experimentalCenters.form.kindPerson.Association', value: 'Asociación' },
  {
    label: 'experimentalCenters.form.kindPerson.investigationCenter',
    value: 'Centro de investigación'
  }
];

export const RESIDENCIA_PERSON = [
  { label: 'experimentalCenters.form.ResidenciaPerson.residente', value: 'Residente' },
  { label: 'experimentalCenters.form.ResidenciaPerson.noresiden', value: 'No Residente' }
];

export const REGISTER_STEPS = [
  {
    step: 1,
    stepName: 'StepLogin',
    name: 'forms.steps.user',
    button: 'auth.buttons.next',
    link: 'auth.buttons.register',
    textLink: 'auth.buttons.hasNotAnAccount',
    enable: true
  },
  {
    step: 2,
    stepName: 'StepOne',
    name: 'forms.steps.profile',
    button: 'auth.buttons.next',
    link: 'auth.buttons.login',
    textLink: 'auth.buttons.hasAnAccount',
    enable: true
  },
  {
    step: 3,
    stepName: 'StepTwo',
    name: 'forms.steps.preferences',
    button: 'auth.buttons.register',
    textLink: '',
    enable: true
  },
  {
    step: 4,
    stepName: 'StepCXTierra',
    name: 'forms.steps.cxtierra',
    button: 'auth.buttons.cxtierra',
    textLink: '',
    enable: true
  },
  {
    step: 4,
    stepName: 'StepStartup',
    name: 'forms.steps.startup',
    button: 'auth.buttons.register',
    textLink: '',
    enable: true
  }
];

export const SUBSCRIPTION_STEPS = [
  {
    step: 1,
    stepName: 'StepLogin',
    name: 'forms.steps.user',
    button: 'auth.buttons.next',
    link: 'auth.buttons.register',
    textLink: 'auth.buttons.hasNotAnAccount',
    enable: true
  },
  {
    step: 2,
    stepName: 'StepOne',
    name: 'forms.steps.profile',
    button: 'auth.buttons.next',
    link: 'auth.buttons.login',
    textLink: 'auth.buttons.hasAnAccount',
    enable: true
  },
  {
    step: 3,
    stepName: 'StepTwo',
    name: 'forms.steps.preferences',
    button: 'auth.buttons.next',
    textLink: '',
    enable: true
  },
  {
    step: 4,
    stepName: 'StepThree',
    name: 'forms.steps.personalInformation',
    button: 'auth.buttons.register',
    textLink: '',
    enable: true
  }
];

export const MARKET_REDIRECT_MODAL = 'marketRedirectModal';

export const SEARCH_INDEXES = [
  {
    name: 'articles',
    value: 'articles:published_date_timestamp:desc',
    icon: 'pt-book-alt',
    bookmark: 'article'
  },
  { name: 'authors', value: 'authors', icon: 'pt-user' },
  {
    name: 'publications',
    value: 'articles:published_date_timestamp:desc',
    icon: 'pt-books',
    bookmark: 'article'
  },
  {
    name: 'events',
    value: 'events:min_session_date:desc',
    icon: 'pt-presentation'
  },
  {
    name: 'posts',
    value: 'posts:published_date_timestamp:desc',
    icon: 'pt-file-alt',
    bookmark: 'article'
  },
  {
    name: 'video-library',
    value: 'video-library:updated_at_timestamp:desc',
    icon: 'pt-play',
    bookmark: 'video'
  },
  {
    name: 'market-comments',
    value: 'market-comments:published_date_timestamp:desc',
    icon: 'pt-chart-growth'
  }
];

export const RETRIEVE_INDEXES = {
  articles: [
    'id',
    'type',
    'slug',
    'title',
    'icons',
    'abstract',
    'category',
    'mediateca',
    'meta_title',
    'main_image',
    'tags_facets',
    'published_date',
    'subsectors_facets'
  ],
  publications: [
    'id',
    'title',
    'meta_title',
    'abstract',
    'main_image',
    'tags_facets',
    'category',
    'mediateca',
    'published_date',
    'slug'
  ],
  events: [
    'id',
    'slug',
    'title',
    'meta_title',
    'abstract',
    'main_image',
    'highlighted',
    'event_format_facets',
    'highlighted_secondary',
    'event_belongs_to_series',
    'tags',
    'tags_facets',
    'content_format',
    'event_date',
    'min_session_date',
    'date_of_first_session',
    'event_format',
    'event_features',
    'list_of_sessions',
    'icons',
    'campus'
  ],
  authors: ['id', 'slug', 'name', 'image', 'job'],
  'video-library': ['id', 'url', 'title', 'keyframe', 'content_format', 'abstract'],
  infographics: [
    'id',
    'title',
    'short_title',
    'external_id',
    'news_source',
    'summary',
    'section',
    'section_facets',
    'thumbnail_url',
    'product_facets'
  ],
  projects: [
    'id',
    'title',
    'meta_title',
    'abstract',
    'main_image',
    'tags_facets',
    'category',
    'mediateca',
    'icons',
    'state_facets',
    'published_date',
    'slug'
  ],
  posts: [
    'id',
    'title',
    'abstract',
    'main_image',
    'tags',
    'category',
    'blog',
    'icons',
    'published_date',
    'slug',
    'blog_facets',
    'blog_slug_facets',
    'author'
  ]
};

export const PROFILE_OPTIONS = [
  { name: 'profile.profileTitle', icon: 'pt-user', component: 'FormUser', hash: 'user' },
  {
    name: 'profile.interestTitle',
    icon: 'pt-clipboard-notes',
    component: 'ProfileSectors',
    hash: 'interest'
  },
  {
    name: 'seeker.nav.notifications',
    icon: 'pt-check-circle',
    component: 'Notifications',
    hash: 'notifications'
  }
];

export const EVENT_INDEXES = [
  { name: 'allMyEvents', value: 'allMyEvents', icon: 'pt-calender' },
  { name: 'coming', value: 'coming', icon: 'pt-calender' },
  { name: 'concerning', value: 'concerning', icon: 'pt-calendar-slash' },
  { name: 'history', value: 'history', icon: 'pt-book-open' }
];

export const SEARCH_INDEXES_SAVED_PAGE = [
  { name: 'myArticles', value: 'article', icon: 'pt-book-alt' },
  { name: 'myVideos', value: 'video', icon: 'pt-play' },
  { name: 'myProjects', value: 'project', icon: 'pt-file-bookmark-alt' },
  { name: 'myPosts', value: 'post', icon: 'pt-file-alt' }
];
export const SEARCH_STARTUP_CALLS_PAGE = [{ name: 'calls', value: 'calls', icon: 'pt-book-alt' }];

export const MENU_SAVED_PAGE = {
  article: 'article',
  video: 'video',
  project: 'project',
  post: 'post'
};
export const MENU_MY_STARTUP = {
  calls: 'calls'
};

export const DEFAULT_COUNTRY = { id: 34, name: 'España (+34)', country: 'España' };

export const STATUS_USER = {
  VERIFIED: 'verified',
  PENDING: 'pending',
  BLOCKED: 'blocked'
};

export const EXCLUDE_FROM_THE_SITEMAP = [
  '/perfil',
  '/establecer-contrasena',
  '/verificacion-usuario',
  '/gracias-por-suscribirte',
  '/aviso-legal',
  '/registro',
  '/accesibilidad',
  '/politica-de-cookies',
  '/politica-de-cookies-cxtierra',
  '/condiciones-de-uso',
  '/privacidad',
  '/mis-eventos',
  '/guardados',
  '/resultados-busqueda',
  '/cuaderno-de-explotacion-cxt',
  '/actualizar-usuario',
  '/mi-startup',
  '/gestion-autenticacion'
];

export const EXCLUDE_ROUTES_IN_GENERATE = [
  '/mercados/sector/frutas',
  '/mercados/sector/hortalizas',
  '/mercados/sector/ganaderia',
  '/mercados/sector/vitivinicola',
  '/mercados/sector/herbaceos',
  '/mercados/sector/aceite-de-oliva'
];

export const HASH_OF_ROUTES = {
  registro: 'app-open-register',
  'iniciar-sesion': 'app-open-login',
  'cerrar-sesion': 'app-logout',
  'restablecer-contrasena': 'open-reset-password',
  sso: 'app-sso'
};

export const CURRENCY = {
  eur: '€',
  usd: '$'
};

export const EVENTFORMAT = {
  online: 'Online',
  onsite: 'Presencial',
  webinar: 'Webinar',
  past_event: 'Evento pasado',
  workshop: 'Workshop',
  hybrid: 'Híbrido',
  soon: 'Próximamente'
};

export const REGISTER_BUTTON_LOCATION = {
  event: 'Event',
  header: 'Header'
};

export const STRUCTURED_DATA_STATE = {
  name: 'Plataforma Tierra SAU'
};

export const STRUCTURED_DATA_TYPE = {
  ARTICLE: 'article',
  INNOVATION: 'innovation',
  EVENT: 'event',
  AUTHOR: 'author',
  HOME: 'home',
  BOOK: 'book',
  PRODUCT: 'product',
  BOOKINNOVATION: 'bookDetail'
};

export const STRUCTURED_DATA_HOME = {
  SAMEAS: [
    'https://www.linkedin.com/company/plataforma-tierra',
    'https://twitter.com/platformtierra',
    'https://www.youtube.com/@plataformatierra124',
    'https://www.facebook.com/PlataformaTierra/'
  ],
  AVISOLEGAL: 'https://www.plataformatierra.es/aviso-legal',
  QUIENESSOMOS: 'https://www.plataformatierra.es/quienes-somos',
  URL: 'https://www.plataformatierra.es/',
  ORGANIZATION: 'Grupo Cooperativo Cajamar'
};

export const STATIC_REDIRECT_ROUTES = ['datagri-2022-ejido-digital-tecnologia-foro-agricultura'];

export const WINE_ROUTE =
  'https://www.grupocooperativocajamar.es/es/agroalimentario/negocio/productos-y-servicios/sector-vitivinicola/?origen_campana=mediosdigital47';

export const RULES_DYNAMIC_FORM = {
  codigo_postal: 'numeric',
  dni: 'dni_nie',
  passport: 'passport',
  telefono: 'numeric|minPhone:9|maxPhone:11'
};

export const AZURE_AD_B2C_REDIRECT_URIS = [
  '/mercados',
  '/innovacion',
  '/actualidad',
  '/herramientas/cxtierra',
  '/herramientas/riego',
  '/herramientas/meteorologia',
  '/herramientas/fertilizacion',
  '/formacion',
  '/comunidad',
  '/publicaciones',
  '/startups'
];

export const SECTIONS_SUBSECTORS = {
  precios: {
    tooltipText: 'Ver precio',
    icon: 'pt-euro-circle'
  },
  comercio: {
    tooltipText: 'Ver comercio',
    icon: 'pt-chart-line'
  },
  consumo: {
    tooltipText: 'Ver consumo',
    icon: 'pt-money-bill'
  },
  'mercados-produccion': {
    tooltipText: 'Ver producción',
    icon: 'pt-truck'
  },
  censo: {
    tooltipText: 'Ver censo',
    icon: 'pt-chart-growth1'
  },
  'datos-mundiales': {
    tooltipText: 'Ver datos mundiales',
    icon: 'pt-globe'
  },
  existencias: {
    tooltipText: 'Ver existencias',
    icon: 'pt-chart-pie-alt'
  },
  rentabilidad: {
    tooltipText: 'Ver rentabilidad',
    icon: 'pt-chart-down'
  }
};
