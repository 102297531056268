import toolsService from '@/services/api/tools/toolsService';

export default {
  async getDailyForecast({ commit }) {
    let dailyForecast = await toolsService.getDailyForecast();
    dailyForecast = dailyForecast.farms
      ? dailyForecast.farms.filter(
          (tag, index, array) => array.findIndex(t => t.municipio === tag.municipio) === index
        )
      : [];
    commit('SET_BY_FIELD', { field: 'dailyForecast', value: dailyForecast });
  },

  async postExploitation({ commit }, { nombre }) {
    const data = await toolsService.postExploitation(nombre);
    return data.result[0];
  },

  async postFarm({ commit }, { idExploitation, payload }) {
    const data = await toolsService.postFarm(idExploitation, payload);
    return data;
  },

  async postZip({ commit }, { zip }) {
    const data = await toolsService.postZip(zip);
    return data;
  },

  identityHyvorData({ commit }, httpClient) {
    return new Promise((resolve, reject) => {
      toolsService.identityHyvorData(httpClient).then(
        resp => resolve(resp),
        err => reject(err)
      );
    });
  }
};
