import profileService from '@/services/api/profile/profileService';
import { capitalize } from '~/utils/helpers/string';

export default {
  async getProfileData({ commit }, pathParam) {
    // path param: userProfile, userPreferences, userCategories, userProfileProfessionSector,
    const { userPreferences } = await profileService.getProfileData(pathParam);
    commit('SET_BY_FIELD', { field: 'userPreferences', value: userPreferences });
  },
  async postProfileData(_, { pathParam, payload }) {
    const data = await profileService.postProfileData(pathParam, payload);
    return data;
  },
  async deleteProfileData(_, pathParam) {
    const data = await profileService.deleteProfileData(pathParam);
    return data;
  },
  async createProfile(_, { pathParam, payload }) {
    const data = await profileService.createProfile(pathParam, payload);
    return data;
  },
  async checkEmailExists(_, { pathParam, payload }) {
    const data = await profileService.checkEmailExists(pathParam, payload);
    return data;
  },
  async sendStartupForm(_, { pathParam, payload }) {
    const data = await profileService.sendStartupForm(pathParam, payload);
    return data;
  },
  async putStartupForm(_, { pathParam, payload }) {
    const data = await profileService.putStartupForm(pathParam, payload);
    return data;
  },
  async getStartupForm(_, { pathParam }) {
    const data = await profileService.getStartupForm(pathParam);
    return data;
  },
  async resendRegisterVerificationByEmail(_, { pathParam, payload }) {
    const data = await profileService.resendRegisterVerificationByEmail(
      pathParam,
      payload,
      this.$axios
    );
    return data;
  },
  async editProfile(_, { pathParam, payload }) {
    const data = await profileService.editProfile(pathParam, payload);
    return data;
  },
  async changePassword(_, { pathParam, payload }) {
    const data = await profileService.resetPassword(pathParam, payload);
    return data;
  },
  async subscribeEvent({ commit }, { slug, payload, courseFormat }) {
    const data = await profileService.subscribeEvent(slug, payload);
    if (courseFormat === 'webinar' || courseFormat === 'hybrid') {
      commit('SET_BY_FIELD', { field: 'urlCourseWebinar', value: data?.data?.join_url });
    }
    return data;
  },
  async getProvinces({ commit }) {
    const {
      data: { result }
    } = await profileService.getProvinces();
    commit('SET_BY_FIELD', { field: 'provinces', value: result });
  },
  async getMunicipalities({ commit }, province) {
    const {
      data: { result }
    } = await profileService.getMunicipalities(province);
    commit('SET_BY_FIELD', { field: 'municipalities', value: result });
  },
  async getCountrys({ commit }, { pathParam }) {
    const { data } = await profileService.getCountrys(pathParam, 'es');
    commit('SET_BY_FIELD', { field: 'countries', value: data?.result });
  },
  async checkUserIsEnrolledInEvent({ commit }, { slug }) {
    commit('SET_BY_FIELD', { field: 'loadingEnrolled', value: 'loading' });
    const data = await profileService.checkUserIsEnrolledInEvent(slug);
    commit('SET_BY_FIELD', { field: 'enrolled', value: data.data.isEnrolled });
    commit('SET_BY_FIELD', { field: 'dateEnrolled', value: data.data.created_at });
    commit('SET_BY_FIELD', { field: 'loadingEnrolled', value: 'success' });
  },

  updateLoadingEnrolled({ commit }) {
    commit('SET_BY_FIELD', { field: 'loadingEnrolled', value: 'success' });
  },
  async updateLead(_, { context }) {
    const data = await profileService.updateLead(context);
    return data;
  },

  resetEnrolled({ commit }) {
    commit('SET_BY_FIELD', { field: 'enrolled', value: false });
    commit('SET_BY_FIELD', { field: 'dateEnrolled', value: undefined });
  },

  resetUserCourses({ commit }) {
    commit('SET_BY_FIELD', { field: 'userCourses', value: undefined });
    commit('SET_BY_FIELD', { field: 'userCoursesHistory', value: undefined });
    commit('SET_BY_FIELD', { field: 'userCoursesYourOwnPace', value: undefined });
    commit('SET_BY_FIELD', { field: 'modalCourses', value: undefined });
  },

  async modalCourses({ commit }, option) {
    const {
      data: { data }
    } = await profileService.modalCourses(option);
    commit('SET_BY_FIELD', { field: 'modalCourses', value: data });
    const existAnyCourseWithoutRead = data?.some(course => course.readed === false);
    commit('SET_BY_FIELD', {
      field: 'existAnyCourseWithoutRead',
      value: existAnyCourseWithoutRead
    });
  },

  async userCourses({ commit }, option) {
    const {
      data: { data }
    } = await profileService.userCourses(option);
    const dataCategorized = data?.map(course => ({ ...course, index_type: 'next' }));
    commit('SET_BY_FIELD', { field: 'userCourses', value: dataCategorized });
  },

  async userCoursesHistory({ commit }, option) {
    const {
      data: { data }
    } = await profileService.userCoursesHistory(option);
    const dataCategorized = data?.map(course => ({ ...course, index_type: 'history' }));
    commit('SET_BY_FIELD', { field: 'userCoursesHistory', value: dataCategorized });
  },

  async userCoursesYourOwnPace({ commit }, option) {
    const {
      data: { data }
    } = await profileService.userCoursesYourOwnPace(option);
    const dataCategorized = data?.map(course => ({ ...course, index_type: 'online' }));
    commit('SET_BY_FIELD', { field: 'userCoursesYourOwnPace', value: dataCategorized });
  },

  async courseAvailability({ commit }, { slug }) {
    const {
      data: {
        success,
        data: {
          online_url: onlineUrl = null,
          zoomUrl = null,
          enrollIn = null,

          event_duration: eventDuration = null,
          progress
        }
      }
    } = await profileService.courseAvailability(slug);
    commit('SET_BY_FIELD', { field: 'isCourseAvailable', value: success });
    commit('SET_BY_FIELD', { field: 'urlCourseClasslife', value: onlineUrl });
    commit('SET_BY_FIELD', { field: 'urlCourseWebinar', value: zoomUrl });
    commit('SET_BY_FIELD', { field: 'enrollIn', value: enrollIn });
    commit('SET_BY_FIELD', { field: 'eventDuration', value: eventDuration });
    return progress;
  },

  resetUrlCourseClasslife({ commit }) {
    commit('SET_BY_FIELD', { field: 'urlCourseClasslife', value: null });
  },

  resetUrlCourseWebinar({ commit }) {
    commit('SET_BY_FIELD', { field: 'urlCourseWebinar', value: null });
  },

  setCourseWithoutRead({ commit }, payload) {
    commit('SET_BY_FIELD', { field: 'existAnyCourseWithoutRead', value: payload });
  },

  getFavorites({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (!state.userFavorites) {
        profileService
          .getFavorites('userFavorites')
          .then(
            resp => {
              const {
                data: { userFavorites, unread_count: unreadCount }
              } = resp;
              const articleEntities = [];
              const videoEntities = [];
              const projectEntities = [];
              const postEntities = [];
              const serializedUserFavorites =
                userFavorites != null ? JSON.parse(JSON.stringify(userFavorites)) : [];
              for (let i = 0; i < serializedUserFavorites.length; i++) {
                if (serializedUserFavorites[i].entity === 'article') {
                  articleEntities.push(serializedUserFavorites[i].id_entity);
                } else if (serializedUserFavorites[i].entity === 'video') {
                  videoEntities.push(serializedUserFavorites[i].id_entity);
                } else if (serializedUserFavorites[i].entity === 'project') {
                  projectEntities.push(serializedUserFavorites[i].id_entity);
                } else if (serializedUserFavorites[i].entity === 'post') {
                  postEntities.push(serializedUserFavorites[i].id_entity);
                }
              }
              commit('SET_BY_FIELD', {
                field: 'favoriteArticleEntities',
                value: articleEntities
              });
              commit('SET_BY_FIELD', {
                field: 'favoriteVideoEntities',
                value: videoEntities
              });
              commit('SET_BY_FIELD', {
                field: 'favoriteProjectEntities',
                value: projectEntities
              });
              commit('SET_BY_FIELD', {
                field: 'favoritePostEntities',
                value: postEntities
              });
              commit('SET_BY_FIELD', { field: 'userFavorites', value: serializedUserFavorites });
              if (unreadCount) {
                commit('SET_BY_FIELD', { field: 'existAnyFavWithoutRead', value: true });
              }
              resolve();
            },
            err => reject(err)
          )
          .finally(() => commit('SET_BY_FIELD', { field: 'favoriteLoaded', value: true }));
      } else {
        resolve();
      }
    });
  },

  async postFavorite({ commit }, payload) {
    commit('ADD_OBJECT_TO_ARRAY', {
      field: 'userFavorites',
      value: payload
    });
    commit('ADD_OBJECT_TO_ARRAY', {
      field: `favorite${capitalize(payload.entity)}Entities`,
      value: payload.id_entity
    });
    await profileService.postFavorite('userFavorites', payload);
  },

  async removeFavorite({ commit }, payload) {
    commit('REMOVE_BY_FIELD', {
      field: 'userFavorites',
      key: 'id_entity',
      value: payload.id_entity.toString()
    });
    commit('REMOVE_ITEM_BY_VALUE', {
      field: `favorite${capitalize(payload.entity)}Entities`,
      value: payload.id_entity.toString()
    });
    await profileService.deleteFavorite('userFavorites', payload);
  },

  async deleteFavorites({ commit }, payload) {
    await commit('REMOVE_BY_STATE', payload);
  },

  async setAsReaded(_, { pathParam }) {
    await profileService.setAsReaded(pathParam);
  },

  async visitCenter(_, { pathParam, payload }) {
    const data = await profileService.visitCenter(pathParam, payload);
    return data;
  },

  setAnyFavWithoutRead({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'existAnyFavWithoutRead', value });
  },

  async checkCaptcha(_, payload) {
    await profileService.checkCaptcha(
      {
        pass: true,
        generated_pass_UUID: payload,
        expiration: 120
      },
      this.$axios
    );
  },

  async fileDownload(_, { pathParam, slug }) {
    const resp = await profileService.fileDownload(pathParam, slug);
    return resp.data.data.file_url;
  },

  async checkFileExist(_, { uri }) {
    const resp = await profileService.checkFileExist({ uri });
    return resp;
  },

  async courseRequest(_, { pathParam, payload }) {
    const resp = await profileService.courseRequest(pathParam, payload);
    return resp;
  },

  async getCoursePath(_, { pathParam }) {
    const resp = await profileService.getCoursePath(pathParam);
    return resp.data;
  },

  async dynamicForms({ commit }, { pathParam, fields }) {
    const resp = await profileService.dynamicForms(pathParam, fields);
    commit('SET_BY_FIELD', {
      field: 'dynamicForm',
      value: resp.data.data
    });
    return resp.data.data;
  },

  async surveyPersonalData(_, payload) {
    const data = await profileService.surveyPersonalData(this.$axios, payload);
    return data;
  },

  async notificationsFilters(_, { filters }) {
    const resp = await profileService.notificationsFilters(filters);
    return resp;
  },
  async setReadedNotification(_, notificationId) {
    const resp = await profileService.setReadedNotification(notificationId);
    return resp;
  },
  async setReadedNotificationAll(_, groupId) {
    const params = groupId ? { group_id: groupId } : {};
    const resp = await profileService.setReadedNotificationAll(params);
    return resp;
  },
  async getNotificationsFilters({ commit }) {
    const result = await profileService.getNotificationsFilters(this.$axios);
    const notificationsFilters = result.data;
    let uncheckedNotifications = [];

    commit('SET_BY_FIELD', { field: 'notificationsFilters', value: notificationsFilters });

    notificationsFilters.forEach(e => {
      uncheckedNotifications = [
        ...uncheckedNotifications,
        ...e.filters.filter(filter => filter.enabled === false).map(obj => obj.id)
      ];
    });
    commit('SET_BY_FIELD', { field: 'uncheckedNotifications', value: uncheckedNotifications });
  },
  async getNotificationsList({ commit }) {
    const result = await profileService.getNotificationsList();
    const notifications = result.data;
    commit('SET_BY_FIELD', { field: 'notifications', value: notifications });
  },
  setUncheckedNotifications({ commit }, value) {
    commit('SET_BY_FIELD', { field: 'uncheckedNotifications', value });
  },
  async getCalls({ commit }) {
    const { result } = await profileService.getCalls();
    commit('SET_BY_FIELD', { field: 'calls', value: result });
  },
  setStartupPermisson({ commit }) {
    commit('SET_BY_FIELD', { field: 'startupPermisson', value: true });
  }
};
