import axios from 'axios';

export default defineNuxtPlugin(nuxtApp => {
  const instance = (manualToken = undefined) => {
    const locale = 'es';
    const config = {
      baseURL: process.env.API_HOST,
      headers: {
        'Accept-Language': locale,
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': process.env.API_OCP_APIM_SUBSCRIPTION_KEY,
        Accept: 'application/json',
        withCredentials: true
      }
    };
    const client = axios.create(config);
    client.interceptors.request.use(
      async config => {
        if (manualToken) {
          config.headers.Authorization = manualToken;
        } else if (nuxtApp.$msal) {
          const { acquireToken } = nuxtApp.$msal;
          const idToken = await acquireToken();
          if (idToken) config.headers.Authorization = idToken;
        }
        return config;
      },
      error => Promise.reject(error)
    );
    client.interceptors.response.use(
      response => Promise.resolve(response),
      error => Promise.reject(error)
    );
    return client;
  };
  const setInstance = token => {
    const {
      get: fetchGet,
      put: fetchPut,
      post: fetchPost,
      delete: fetchDelete,
      interceptors
    } = instance(token);
    return { fetchGet, fetchPut, fetchPost, fetchDelete, interceptors };
  };
  return {
    provide: {
      axios: { ...setInstance() },
      axiosManualToken: manualToken => ({ ...setInstance(manualToken) })
    }
  };
});
