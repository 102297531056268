<template>
  <div>
    <NuxtLayout>
      <div class="container">
        <div class="content">
          <NuxtImg src="/error_image.svg" width="100" height="100" />
          <h1>{{ $t('message.error.page.title') }}</h1>
          <!-- Verificar aquí los statusCode 500, 401, etc... -->
          <h2 v-if="[401, 404].indexOf(error.statusCode) > -1" class="mt-3">
            {{ $t(`message.error.page.${error.statusCode}`) }}
          </h2>
          <h2 v-else class="mt-3">{{ $t('message.error.page.undefined') }}</h2>
          <a
            class="btn btn__blue btn__blue--big w-full md:w-auto justify-center mt-4 cursor-pointer"
            @click="clearError({ redirect: '/' })"
          >
            {{ $t('ui.button.back') }}
          </a>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
<script>
export default {
  name: 'PtError',

  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 50px 0;
}
</style>
