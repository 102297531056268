import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: () => ({
    allCalls: [],
    common: null,
    process: null,
    home: {},
    innovation: {},
    events: {},
    eventsRelated: {},
    eventsRelatedSubsector: {},
    eventsCalendar: [],
    eventsBySubsectors: [],
    market: {},
    centers: {},
    about: {},
    sectorSection: {},
    subsectorSection: {},
    subsectors: [],
    articles: [],
    legal: {},
    training: {},
    author: {},
    articlesMostRecent: {
      innovation: []
    },
    feedback: null,
    infogram: {},
    loading: false,
    articlesCategorySlug: [],
    articlesBySubsector: [],
    articlesBySubsectors: [],
    articleByTag: {},
    projects: [],
    products: { info: {}, product: {} },
    hightLightEvent: {},
    authorPublications: [],
    coordinatorPublications: [],
    upcomingEvents: [],
    sectors: [],
    newsSingleType: {},
    newsSubsector: [],
    newsBySlug: {},
    articlesSaved: [],
    reportVersions: [],
    marketComments: [],
    lastMarketComments: [],
    surveys: {},
    eventSerie: {},
    lastReports: null,
    project: {},
    postBySlug: {},
    blogBySlug: {},
    community: {},
    landingCxtierra: {},
    lastPost: {},
    lastPostByBlog: {},
    invitationBySlug: {},
    blogsWithAuthors: [],
    blogsByAuthor: []
  }),
  getters,
  actions,
  mutations
};
