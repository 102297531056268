import { injectScript } from '@/utils/helpers/script';

export default defineNuxtPlugin(nuxtApp => {
  onNuxtReady(() => {
    nuxtApp.$emitter.$on('hs-script-loader', () => {
      // Llamar al hsforms https://js.hsforms.net/forms/shell.js
      const hubspotUrl = `https://js.hs-scripts.com/${process.env.APP_HUBSPOT_TRACKING_CODE}.js`;
      injectScript(hubspotUrl, '_hsp', true, {
        id: 'hs-script-loader',
        async: true,
        defer: true
      });
    });
  });
});
