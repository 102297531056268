// Check if object is empty
export const isEmptyObject = obj =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

// Get field of objects and arrays
export const fetchFromObject = (obj, prop) => {
  if (typeof obj === 'undefined') return false;
  const index = prop.indexOf('.');
  if (index > -1) {
    return fetchFromObject(obj[prop.substring(0, index)], prop.substr(index + 1));
  }
  return obj[prop];
};

export const removeObjectFromArrayByField = (obj, prop, key, value) => {
  const result = obj[prop].filter(item => item[key] !== value);
  setValueObjectByField(obj, prop, result);
};

export const removeItemFromArrayByValue = (obj, prop, value) => {
  const index = obj[prop].indexOf(value);
  if (index !== -1) {
    obj[prop].splice(index, 1);
  }
};

export const addObjectToArray = (obj, prop, value) => {
  obj[prop].unshift(value);
};

// Set value in field at objects and arrays
export const setValueObjectByField = (obj, prop, value) => {
  if (typeof obj !== 'undefined') {
    const index = prop.indexOf('.');
    if (index > -1) {
      setValueObjectByField(obj[prop.substring(0, index)], prop.substr(index + 1), value);
    } else {
      obj[prop] = value;
    }
  }
};

export const mapArraryMasterData = data =>
  data.map(item => ({ label: item.label, value: item.value }));

export const checkIncludeArray = (includeMe, needInclusions) => {
  let someFlag = true;
  for (let i = 0; i < needInclusions.length; i += 1) {
    if (!includeMe.includes(needInclusions[i])) {
      someFlag = false;
      break;
    }
  }
  return someFlag;
};

/**
 * Sort array of objects based on another array
 */
export const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
};

export const recursiveValue = arr => {
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
      this.getValueRecursive(arr[i]);
    } else {
      return arr[i];
    }
  }
};

export const mapElements = (elements, section, key = 'category') => {
  if (elements) {
    function mapField(obj) {
      let result;
      if (obj != null && typeof obj === 'object') {
        if (Array.isArray(obj)) result = obj.map(mapField);
        else {
          if (key in obj) {
            const field = obj[key];
            const newField = { ...field, section };
            result = { ...obj, [key]: newField };
          }
          for (const prop in obj) {
            const subObj = obj[prop];
            const subResult = mapField(subObj);
            if (subResult) {
              if (!result) result = { ...obj };
              result[prop] = subResult;
            }
          }
        }
      }
      return result ?? obj;
    }
    if (Array.isArray(elements)) return elements.map(mapField);
    else return mapField(elements);
  } else {
    return elements;
  }
};

export const mapGraphQuery = data => {
  if (Array.isArray(data)) {
    return data.map(item => mapGraphQuery(item));
  } else if (data != null && typeof data === 'object') {
    const result = {};
    for (const key in data) {
      if (['attributes', 'data'].includes(key)) {
        if (data.id != null) data[key] = { ...data[key], id: data.id };
        return mapGraphQuery(data[key]);
      } else {
        result[key] = mapGraphQuery(data[key]);
      }
    }
    return result;
  } else {
    return data;
  }
};

export const filterObject = (elements, filters, rule, strict = false) => {
  const filterFn = k => {
    if (strict) {
      if (Array.isArray(filters)) {
        return rule ? filters.every(filter => k === filter) : filters.every(filter => k !== filter);
      } else {
        return rule ? k === filters : k !== filters;
      }
    } else {
      const filterRegex = new RegExp(Array.isArray(filters) ? filters.join('|') : filters);
      return rule ? filterRegex.test(k) : !filterRegex.test(k);
    }
  };
  return Object.entries(elements).reduce((acc, [k, v]) => {
    if (filterFn(k)) acc[k] = v;
    return acc;
  }, {});
};

export const jsonToQueryString = json =>
  '?' +
  Object.keys(json)
    .map(key => key + '=' + json[key])
    .join('&');

export const objectCleaner = _object => {
  const cleanedObj = { ..._object };
  Object.keys(cleanedObj).forEach(key => {
    if ([null, undefined, ''].includes(cleanedObj[key])) {
      delete cleanedObj[key];
    }
  });

  return cleanedObj;
};
