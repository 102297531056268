export const injectScript = (url, lib, body = true, attrs = {}) => {
  let ctr = 0;
  let scriptExists = false;

  Array.prototype.forEach.call(document.scripts, script => {
    scriptExists = script.src === url;
  });

  if (!scriptExists) {
    const el = document.createElement('script');
    el?.setAttribute('src', url);
    if (attrs.async === undefined || (attrs.async !== undefined && attrs.async))
      el?.setAttribute('async', '');
    if (attrs.defer !== undefined && attrs.defer) el?.setAttribute('defer', '');
    el?.setAttribute('type', 'application/javascript');
    Object.keys(attrs)
      .filter(key => key !== 'async' && key !== 'defer')
      .forEach(key => {
        if (attrs[key]) {
          el?.setAttribute(key, attrs[key]);
        }
      });
    document[body ? 'body' : 'head'].appendChild(el);
  }

  return new Promise((resolve, reject) => {
    const handle = window.setInterval(() => {
      if (window[lib]) {
        resolve();
        clearInterval(handle);
      }
      ctr += 1;
      if (ctr > 1000) {
        reject(new Error(`Unable to load ${url}`));
        clearInterval(handle);
      }
    }, 5);
  });
};

export const downloadFileCrossBrowser = url => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  if (!/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noopener noreferrer');
  }
  document.body.appendChild(link);
  link.click();
  setTimeout(() => document.body.removeChild(link), 100);
};
