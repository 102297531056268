<template>
  <div>
    <NuxtLayout>
      <VitePwaManifest />
      <NuxtLoadingIndicator
        color="repeating-linear-gradient(to right,#109dc2 0%,#109dc2 50%,#109dc2 100%)"
        :throttle="100"
      />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
