import { print } from 'graphql/language/printer';
export default class strapiService {
  static async gqlQuery(query, variables) {
    const data = await $fetch(
      `${process.env.API_HOST_STRAPI_GRAPHQL}${process.env.API_GRAPHQL_QUERY_EXT}`,
      {
        method: 'POST',
        body: {
          query: print(query),
          variables
        }
      }
    );
    return data;
  }
}
