/* eslint-disable max-lines */
import { i18n } from '@/utils/i18n';
import {
  getHome,
  getInnovation,
  getEventById,
  getCommons,
  getLandingPageTraining,
  getLegalPages,
  getProcess,
  getArticleBySlug,
  getArticlesMostRecent,
  getArticlesSubsector,
  getArticlesSubsectors,
  getAuthorBySlug,
  getInfographicBySubsector,
  getInfographic,
  getArticlesCategorySlug,
  getArticlesByInfCategory,
  getEventHighLighted,
  getMarket,
  getSubsectorSection,
  getArticlesByAuthor,
  getArticlesByCoordinator,
  getEventsByTags,
  getEventsBySubsector,
  getEventsBySubsectors,
  getEventsCalendar,
  getSectors,
  getSubsectors,
  getNewsSingleType,
  getNewsSubsector,
  getNewsBySlug,
  getArticlesSavedById,
  getVideosSavedById,
  getProjectsSavedById,
  getPostsSavedById,
  getCenters,
  getAbout,
  getArticleReportVersions,
  getMarketCommentsBySubsector,
  getmarketCommentsBySlug,
  getLastMarketComment,
  getSurveyBySlug,
  getFeedbackByPath,
  getEventsBySerie,
  getLastReports,
  getNewsArticles,
  getProjectsByCenters,
  getProjectsBySlug,
  getPostBySlug,
  getBlogBySlug,
  getCommunity,
  getLastPost,
  getBlogsWithAuthors,
  getLastPostByBlog,
  getBlogsByAuthor,
  getProductsInfo,
  getLandingCxtierra,
  getProductsPest,
  getProductsMeteo,
  getProductsIrrigation,
  getProductsFertilization,
  getInvitationBySlug,
  getStartups,
  getCallForProposal,
  getCallForProposalBySlug,
  getDynamicFormByStartupSlug,
  getDynamicFormBySlug
} from '@/services/api/strapi';
import { mapOrder, mapElements } from '~/utils/helpers/object';
import { NEWSROOMS_BASE_URL } from '~/utils/enums';
const newsRoomsBaseUrl = NEWSROOMS_BASE_URL.replace('/', '');
const locale = i18n.global.locale;
export default {
  async getHome({ commit }) {
    const {
      data: { home }
    } = await getHome(locale.value);
    commit('SET_BY_FIELD', { field: 'home', value: home });
    return home;
  },

  async getInnovation({ commit }) {
    const {
      data: { innovation }
    } = await getInnovation(locale.value);
    commit('SET_BY_FIELD', { field: 'innovation', value: innovation });
    return innovation;
  },

  async getMarket({ commit }) {
    const {
      data: { market }
    } = await getMarket(locale.value);
    commit('SET_BY_FIELD', { field: 'market', value: market });
    return market;
  },

  async getCenters({ commit }) {
    const {
      data: { center }
    } = await getCenters(locale.value);
    commit('SET_BY_FIELD', { field: 'centers', value: center });
  },

  async getAbout({ commit }) {
    const {
      data: { about }
    } = await getAbout(locale.value);
    commit('SET_BY_FIELD', { field: 'about', value: about });
  },

  async getSubsectorSection({ commit }, { subsector }) {
    const {
      data: {
        market: {
          subsector_section: subsectorSection,
          bg_image_header_default: bgImageHeaderDefault
        }
      }
    } = await getSubsectorSection(locale.value);
    subsectorSection.meta.title = subsectorSection.meta.title.replace('{{subsector}}', subsector);
    commit('SET_BY_FIELD', {
      field: 'subsectorSection',
      value: { subsectorSection, bgImageHeaderDefault }
    });
  },

  async getEventById({ commit }, { slug }) {
    const {
      data: { events }
    } = await getEventById(locale.value, slug);
    commit('SET_BY_FIELD', { field: 'events', value: events[0] });
    return events[0];
  },

  async getEventsCalendar({ commit }) {
    const {
      data: { events }
    } = await getEventsCalendar(locale.value);
    commit('SET_BY_FIELD', { field: 'eventsCalendar', value: events });
  },

  async getEventsByTags({ commit }, { tags, limit, slug }) {
    const {
      data: { events }
    } = await getEventsByTags(locale.value, limit, slug, tags);
    commit('SET_BY_FIELD', { field: 'eventsRelated', value: events });
  },

  async getEventsBySerie({ commit }, { serie, slug }) {
    const {
      data: { events }
    } = await getEventsBySerie(locale.value, serie, slug);
    commit('SET_BY_FIELD', { field: 'eventSerie', value: events });
  },

  async getEventsBySubsector({ commit }, { limit, subsector }) {
    const {
      data: { events }
    } = await getEventsBySubsector(locale.value, limit, subsector);
    commit('SET_BY_FIELD', { field: 'eventsRelatedSubsector', value: events });
  },

  async getEventsBySubsectors({ commit }, { limit, subsectors }) {
    const {
      data: { events }
    } = await getEventsBySubsectors(locale.value, limit ?? 2, subsectors);
    commit('SET_BY_FIELD', { field: `eventsBySubsectors`, value: events });
  },

  async getArticleBySlug({ commit }, { slug }) {
    const {
      data: { articles }
    } = await getArticleBySlug(locale.value, slug);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    const article = mappedArticles[0];
    commit('SET_BY_FIELD', { field: 'articles', value: article });
    return article;
  },

  async getmarketCommentsBySlug({ commit }, { slug }) {
    const {
      data: { marketComments }
    } = await getmarketCommentsBySlug(locale.value, slug);
    const comment = mapElements(marketComments, newsRoomsBaseUrl);
    return comment[0];
  },

  async getArticleReportVersions({ commit }, { slug }) {
    const {
      data: { articles }
    } = await getArticleReportVersions(locale.value, slug);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'reportVersions', value: mappedArticles });
    return mappedArticles;
  },

  async getAuthorBySlug({ commit }, { slug }) {
    const {
      data: { authors }
    } = await getAuthorBySlug(locale.value, slug);
    commit('SET_BY_FIELD', { field: 'author', value: authors[0] });
    return authors;
  },

  async getArticlesMostRecent({ commit }, { limit, type, categories }) {
    const {
      data: { articles }
    } = await getArticlesMostRecent(locale.value, limit ?? 8, categories);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: `articlesMostRecent.${type}`, value: mappedArticles });
  },

  async getArticlesSubsectors({ commit }, { limit, subsectors }) {
    const {
      data: { articles }
    } = await getArticlesSubsectors(locale.value, limit ?? 2, subsectors);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: `articlesBySubsectors`, value: mappedArticles });
  },

  async getEventHighLighted({ commit }) {
    const {
      data: { events }
    } = await getEventHighLighted(locale.value, 1);
    commit('SET_BY_FIELD', { field: 'hightLightEvent', value: events.length ? events[0] : {} });
  },

  async getArticlesCategorySlug({ commit }, { category, slug, limit }) {
    const {
      data: { articles }
    } = await getArticlesCategorySlug(locale.value, limit, category, slug);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', {
      field: 'articlesCategorySlug',
      value: mappedArticles
    });
    return mappedArticles;
  },

  async getArticlesByInfCategory({ commit }, { category, limit }) {
    const {
      data: { articles }
    } = await getArticlesByInfCategory(locale.value, limit, category);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', {
      field: 'articleByTag',
      value: mappedArticles.length ? articles[0] : {}
    });
  },

  async getLastReports({ commit }, { limit }) {
    const {
      data: { articles }
    } = await getLastReports(locale.value, limit);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', {
      field: 'lastReports',
      value: mappedArticles.length ? mappedArticles : []
    });
  },

  async getArticlesSubsector({ commit }, { subsector, limit }) {
    const {
      data: { articles }
    } = await getArticlesSubsector(locale.value, limit, subsector);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'articlesBySubsector', value: mappedArticles });
  },

  async getProjectsByCenters({ commit, getters }, { operation = 'replace', limit = 3, center }) {
    const sorts = ['id', 'title:asc', 'title:desc', 'slug:asc', 'slug:desc'];
    const currentProjects = getters.getByField('projects');
    const currentSort = sorts[~~(Math.random() * sorts.length)];
    const currentCenter = Array.isArray(center) ? center : [center];
    const {
      data: { projects }
    } = await getProjectsByCenters(locale.value, limit, currentSort, currentCenter);
    commit('SET_BY_FIELD', {
      field: 'projects',
      value: operation === 'add' ? [...projects, ...currentProjects] : projects
    });
  },

  async getProjectsBySlug({ commit }, { slug }) {
    const {
      data: { projects }
    } = await getProjectsBySlug(locale.value, slug);
    commit('SET_BY_FIELD', { field: 'project', value: projects.length ? projects[0] : {} });
    return projects.length ? projects[0] : {};
  },

  async getPostBySlug({ commit }, { slug }) {
    const {
      data: { posts }
    } = await getPostBySlug(slug);
    commit('SET_BY_FIELD', { field: 'postBySlug', value: posts.length ? posts[0] : {} });
    return posts.length ? posts[0] : {};
  },

  async getBlogBySlug({ commit }, { slug }) {
    const {
      data: { blogs }
    } = await getBlogBySlug(locale.value, slug);
    commit('SET_BY_FIELD', { field: 'blogBySlug', value: blogs.length ? blogs[0] : {} });
    return blogs.length ? blogs[0] : {};
  },

  async getCallForProposalBySlug({ commit }, { slug }) {
    const {
      data: { callForProposals }
    } = await getCallForProposalBySlug(locale.value, slug);
    commit('SET_BY_FIELD', {
      field: 'callForProposals',
      value: callForProposals.length ? callForProposals[0] : {}
    });
    return callForProposals.length ? callForProposals[0] : {};
  },

  async getCallForProposals({ commit }) {
    const {
      data: { callForProposals }
    } = await getCallForProposal(locale.value);
    commit('SET_BY_FIELD', {
      field: 'allCalls',
      value: callForProposals.length ? callForProposals : {}
    });
    return callForProposals.length ? callForProposals : {};
  },

  async getDynamicFormByStartupSlug({ commit }, { slug }) {
    const {
      data: { callForProposals }
    } = await getDynamicFormByStartupSlug(locale.value, slug);
    commit('SET_BY_FIELD', {
      field: 'callForProposalsForm',
      value: callForProposals.length ? callForProposals[0] : {}
    });
    return callForProposals.length ? callForProposals[0] : {};
  },

  async getDynamicFormBySlug({ commit }, { slug }) {
    const {
      data: { dynamicForms }
    } = await getDynamicFormBySlug(locale.value, slug);
    commit('SET_BY_FIELD', {
      field: 'dynamicForms',
      value: dynamicForms.length ? dynamicForms[0] : {}
    });
    return dynamicForms.length ? dynamicForms[0] : {};
  },

  getCommons({ state, commit }, locale) {
    return new Promise((resolve, reject) => {
      if (!state.common) {
        getCommons(locale).then(
          resp => {
            const {
              data: { common }
            } = resp;
            commit('SET_BY_FIELD', { field: 'common', value: common });
            resolve();
          },
          err => {
            reject(err);
          }
        );
      } else {
        resolve();
      }
    });
  },

  getProcess({ state, commit }, locale) {
    return new Promise((resolve, reject) => {
      if (!state.process) {
        getProcess(locale).then(
          resp => {
            const {
              data: { process }
            } = resp;
            commit('SET_BY_FIELD', { field: 'process', value: process });
            resolve();
          },
          err => {
            reject(err);
          }
        );
      } else {
        resolve();
      }
    });
  },

  async getLegalPages({ commit }) {
    const {
      data: { legal }
    } = await getLegalPages(locale.value);
    commit('SET_BY_FIELD', { field: 'legal', value: legal });
    return legal;
  },

  async getLandingPageTraining({ commit }) {
    const {
      data: { training }
    } = await getLandingPageTraining(locale.value);
    commit('SET_BY_FIELD', { field: 'training', value: training });
    return training;
  },

  async getInfographicBySubsector({ commit }, { subsector }) {
    const {
      data: { infographics }
    } = await getInfographicBySubsector(locale.value, 1, subsector);
    commit('SET_BY_FIELD', {
      field: 'infogram',
      value: infographics.length ? infographics[0] : {}
    });
  },

  async getInfographic({ commit }) {
    const {
      data: { infographics }
    } = await getInfographic(locale.value);
    commit('SET_BY_FIELD', { field: 'lastinfogram', value: infographics });
    return infographics;
  },

  async getArticlesByAuthor({ commit }, { slug, limit }) {
    const {
      data: { articles }
    } = await getArticlesByAuthor(locale.value, slug, limit);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'authorPublications', value: mappedArticles });
  },

  async getArticlesByCoordinator({ commit }, { slug }) {
    const {
      data: { articles }
    } = await getArticlesByCoordinator(locale.value, slug);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'coordinatorPublications', value: mappedArticles });
  },

  async getSectors({ commit }) {
    const {
      data: { subsectors }
    } = await getSectors(locale.value);
    const mapSubSectors = subsectors.map(sector => ({
      key: sector.sector,
      connection: {
        values: subsectors
          .filter(item => item.sector === sector.sector)
          .map(item => ({
            subsector_id: item.id,
            sector_slug: sector.sector,
            subsector_slug: item.slug,
            subsector_label: item.label
          }))
      }
    }));
    const sectors = [...new Map(mapSubSectors.map(m => [m.key, m])).values()].sort((a, b) =>
      a.key.localeCompare(b.key)
    );
    commit('SET_BY_FIELD', {
      field: 'sectors',
      value: sectors
    });
  },

  async getSubsectors({ commit }) {
    const {
      data: { subsectors }
    } = await getSubsectors(locale.value);
    commit('SET_BY_FIELD', { field: 'subsectors', value: subsectors });
    return subsectors;
  },

  async getNewsBySlug({ commit }, { slug }) {
    const {
      data: { articles }
    } = await getNewsBySlug(locale.value, slug);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', {
      field: 'newsBySlug',
      value: mappedArticles.length ? mappedArticles[0] : {}
    });
    return mappedArticles.length ? mappedArticles[0] : {};
  },

  async getNewsSingleType({ commit }) {
    const {
      data: { newsSingleType }
    } = await getNewsSingleType(locale.value);
    const mappedNews = mapElements(newsSingleType, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'newsSingleType', value: mappedNews });
    return newsSingleType;
  },

  async getNewsSubsector({ commit }, { limit }) {
    const {
      data: { newsSingleType }
    } = await getNewsSubsector(locale.value, limit);
    const mappedNews = mapElements(newsSingleType, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'newsSubsector', value: mappedNews });
  },

  async getNewsArticles({ commit }, { limit }) {
    const {
      data: { articles }
    } = await getNewsArticles(locale.value, limit);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'newsArticles', value: mappedArticles });
  },

  async getArticlesSavedById(_, entities) {
    const setterEntities = entities.filter(Boolean);
    const {
      data: { articles }
    } = await getArticlesSavedById(locale.value, setterEntities);
    const mappedArticles = mapElements(articles, newsRoomsBaseUrl);
    return articles ? mapOrder(mappedArticles, setterEntities, 'id') : [];
  },

  async getVideosSavedById(_, entities) {
    const setterEntities = entities.filter(Boolean);
    const {
      data: { videoLibraries }
    } = await getVideosSavedById(locale.value, setterEntities);
    return videoLibraries ? mapOrder(videoLibraries, setterEntities, 'id') : [];
  },

  async getProjectsSavedById(_, entities) {
    const setterEntities = entities.filter(Boolean);
    const {
      data: { projects }
    } = await getProjectsSavedById(locale.value, setterEntities);
    return projects ? mapOrder(projects, setterEntities, 'id') : [];
  },

  async getPostsSavedById(_, entities) {
    const setterEntities = entities.filter(Boolean);
    const {
      data: { posts }
    } = await getPostsSavedById(locale.value, setterEntities);
    return posts ? mapOrder(posts, setterEntities, 'id') : [];
  },

  async getMarketCommentsBySubsector({ commit }, { slug, limit }) {
    const {
      data: { marketComments }
    } = await getMarketCommentsBySubsector(locale.value, limit, slug);
    const mappedMarketComments = mapElements(marketComments, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'marketComments', value: mappedMarketComments });
  },

  async getLastMarketComment({ commit }, { limit }) {
    const {
      data: { marketComments }
    } = await getLastMarketComment(locale.value, limit);
    const mappedMarketComments = mapElements(marketComments, newsRoomsBaseUrl);
    commit('SET_BY_FIELD', { field: 'lastMarketComments', value: mappedMarketComments });
  },

  async getSurveyBySlug({ commit }, { slug }) {
    const {
      data: { surveys }
    } = await getSurveyBySlug(locale.value, slug);
    commit('SET_BY_FIELD', { field: 'surveys', value: surveys[0] });
  },

  async getFeedbackByPath({ commit }, { path }) {
    if (path.slice(-1) === '/') path = path.slice(0, -1);
    const {
      data: { feedbacks }
    } = await getFeedbackByPath(locale.value, path);
    commit('SET_BY_FIELD', {
      field: 'feedback',
      value: feedbacks && feedbacks.length ? feedbacks[0] : null
    });
  },

  async getCommunity({ commit }) {
    const {
      data: { community }
    } = await getCommunity(locale.value);
    commit('SET_BY_FIELD', { field: 'community', value: community });
    return community;
  },

  async getStartups({ commit }) {
    const {
      data: { startup }
    } = await getStartups(locale.value);
    commit('SET_BY_FIELD', { field: 'startup', value: startup });
    return startup;
  },

  async getLandingCxtierra({ commit }) {
    const {
      data: { landingCxtierra }
    } = await getLandingCxtierra(locale.value);
    commit('SET_BY_FIELD', { field: 'landingCxtierra', value: landingCxtierra });
    return landingCxtierra;
  },

  async getLastPost({ commit }, limit) {
    const {
      data: { posts }
    } = await getLastPost(limit);
    commit('SET_BY_FIELD', {
      field: 'lastPost',
      value: posts?.length > 1 ? posts : posts?.length === 1 ? posts[0] : null
    });
  },

  async getLastPostByBlog({ commit }, { limit, slug }) {
    const {
      data: { posts }
    } = await getLastPostByBlog(limit, slug);
    commit('SET_BY_FIELD', { field: 'lastPostByBlog', value: posts.length ? posts[0] : null });
  },

  async getBlogsWithAuthors({ commit }, limit) {
    const {
      data: { blogs }
    } = await getBlogsWithAuthors(locale.value, limit);
    commit('SET_BY_FIELD', { field: 'blogsWithAuthors', value: blogs });
  },

  async getBlogsByAuthor({ commit }, { slug }) {
    const {
      data: { blogs }
    } = await getBlogsByAuthor(locale.value, slug);
    commit('SET_BY_FIELD', { field: 'blogsByAuthor', value: blogs });
  },

  async getProducts({ commit }, { product, field }) {
    let res;
    switch (product) {
      case 'info':
        res = await getProductsInfo(locale.value);
        break;
      case 'meteorologia':
        res = await getProductsMeteo(locale.value);
        break;
      case 'riego':
        res = await getProductsIrrigation(locale.value);
        break;
      case 'fertilizacion':
        res = await getProductsFertilization(locale.value);
        break;
      case 'plagas':
        res = await getProductsPest(locale.value);
        break;
    }
    if (res) {
      const { data: key } = res;
      const data = Object.keys(key).reduce((acc, cur) => ({ ...acc, ...key[cur] }), {});
      commit('SET_BY_FIELD', { field: `products.${field}`, value: data });
      return data;
    }
  },

  async getInvitationBySlug({ commit }, { slug }) {
    const {
      data: { invitations }
    } = await getInvitationBySlug(locale.value, slug);
    commit('SET_BY_FIELD', {
      field: 'invitationBySlug',
      value: invitations.length > 0 ? invitations[0] : {}
    });
  }
};
