<template>
  <div class="animate-pulse">
    <div class="px-4 h-64">
      <div class="h-2.5 bg-gray rounded-full w-full" />
      <div class="h-2.5 bg-gray rounded-full w-full mt-1" />
      <div class="h-2.5 bg-gray rounded-full mt-1 mb-3 w-2/5" />
      <div class="h-2 rounded-full w-4/5 bg-gray-soft" />
      <div class="h-2 rounded-full w-full bg-gray-soft my-1.5" />
      <div class="h-2 rounded-full w-5/6 bg-gray-soft" />
      <div class="h-2 rounded-full w-11/12 bg-gray-soft my-1.5" />
      <div class="h-2 rounded-full w-4/5 bg-gray-soft" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PtSkeletonArticleNoImg'
};
</script>
