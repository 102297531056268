import {
  setValueObjectByField,
  removeObjectFromArrayByField,
  addObjectToArray,
  removeItemFromArrayByValue
} from '@/utils/helpers/object';

export default {
  SET_BY_FIELD: (state, { field, value }) => {
    setValueObjectByField(state, field, value);
  },
  REMOVE_BY_FIELD: (state, { field, key, value }) => {
    removeObjectFromArrayByField(state, field, key, value);
  },
  REMOVE_BY_STATE: (state, value) => {
    state.userFavorites = value;
  },
  REMOVE_ITEM_BY_VALUE: (state, { field, value }) => {
    removeItemFromArrayByValue(state, field, value);
  },
  ADD_OBJECT_TO_ARRAY: (state, { field, value }) => {
    addObjectToArray(state, field, value);
  }
};
