export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.provide('scrollToSmoothly', (pos, attr = 'id', time = 300) => {
    let start;
    let selector;
    switch (attr) {
      case 'class':
        selector = '.';
        break;
      case 'tag':
        selector = '';
        break;
      default:
        selector = '#';
    }
    const currentPos = window.scrollY;
    const countHead = window.matchMedia('(min-width: 768px)').matches ? 2 : 1;
    const header = document.querySelector('header') ?? { offsetHeight: 0 };
    const element = typeof pos === 'string' ? document.querySelector(`${selector}${pos}`) : null;
    const endPos = Number(
      element !== null ? element.offsetTop - header.offsetHeight * countHead : pos
    );
    Number(time);
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start;
      const progress = currentTime - start;
      if (currentPos < endPos) {
        window.scrollTo(0, ((endPos - currentPos) * progress) / time + currentPos);
      } else {
        window.scrollTo(0, currentPos - ((currentPos - endPos) * progress) / time);
      }
      if (progress < time) {
        window.requestAnimationFrame(step);
      } else {
        window.scrollTo(0, endPos);
      }
    });
  });
});
