import { eventData } from '~/model/eventData';
export default {
  data: () => ({
    eventData
  }),
  methods: {
    generateEvent() {
      this.eventData.checkbox.type = this.course.event_features.course_partner;
    }
  }
};
