import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: () => ({
    userPreferences: [],
    userProfile: [],
    countries: [],
    provinces: [],
    municipalities: [],
    enrolled: false,
    dateEnrolled: undefined,
    loadingEnrolled: undefined,
    userCourses: undefined,
    modalCourses: undefined,
    isCourseAvailable: false,
    urlCourseClasslife: null,
    urlCourseWebinar: undefined,
    eventDuration: undefined,
    enrollIn: undefined,
    userFavorites: undefined,
    userCoursesYourOwnPace: undefined,
    userCoursesHistory: undefined,
    favoriteArticleEntities: undefined,
    favoriteVideoEntities: undefined,
    favoriteProjectEntities: undefined,
    favoritePostEntities: undefined,
    favoriteLoaded: false,
    existAnyCourseWithoutRead: false,
    existAnyFavWithoutRead: false,
    urlDownload: undefined,
    notifications: [],
    registerOrigin: '',
    notificationsFilters: [],
    uncheckedNotifications: [],
    calls: [],
    dynamicForm: null,
    startupPermisson: false
  }),
  getters,
  actions,
  mutations
};
